import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { GroupRoute } from '@/libs/routing';
import { StatusEnum } from '@/store/messages';
import { timestampToGtfsFormat } from '@/libs/helpers/dates';

const ClickableCell = defineAsyncComponent(() => import('@/components/Table/cells/ClickableCell.vue'));
const CellDate = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));

/** @enum {string} */
export const ColumnKey = {
  SENDER_NAME: 'senderName',
  TRIP: 'tripName',
  CONTENT: 'content',
  SEND_DATE: 'sendDate',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'inboxListDatagrid',
    searchFields: [ColumnKey.SENDER_NAME, ColumnKey.CONTENT],
    defaultSortBy: [{ key: ColumnKey.SEND_DATE, order: SortOrder.DESC }],
    hasActions: true,
    hasFirstRowSticky: false,
    selectableList: true,
    showColumnSelector: false,

    columns: [
      // SENDER DEVICE
      new DataGridColumn({
        key: ColumnKey.SENDER_NAME,
        title: 'column.sender',
        selectable: false,
        filterable: true,
        cellBuilder([value, object]) {
          return {
            component: ClickableCell,
            props: {
              highlight: true,
              link: {
                name: GroupRoute.DEVICE_DETAILLED,
                params: { deviceId: object.senderId },
                query: { date: timestampToGtfsFormat(object.sendDate) },
              },
              displayValue: value,
            },
            value,
          };
        },
      }),
      // TRIP
      new DataGridColumn({
        key: ColumnKey.TRIP,
        title: 'column.trip',
        sortable: false,
        selectable: false,
        cellBuilder([value, object]) {
          const link = object.tripId
            ? {
                name: GroupRoute.TRIP_DETAILED,
                params: { tripId: object.tripId },
                query: { date: timestampToGtfsFormat(object.sendDate) },
              }
            : null;
          return {
            component: ClickableCell,
            props: {
              highlight: true,
              link,
              displayValue: value,
            },
            value,
          };
        },
      }),

      // CONTENT MESSAGE
      new DataGridColumn({
        key: ColumnKey.CONTENT,
        title: 'column.messageContent',
        selectable: false,
        cellBuilder([value, object], { click }) {
          return {
            component: ClickableCell,
            events: { click },
            props: {
              highlight: object.status !== StatusEnum.READ,
              displayValue: value,
            },
            value,
          };
        },
      }),
      // SENT DATE
      new DataGridColumn({
        key: ColumnKey.SEND_DATE,
        title: 'column.sendDate',
        selectable: false,
        cellBuilder([value, object]) {
          return {
            component: CellDate,
            props: {
              highlight: object.status !== StatusEnum.READ,
              date: value ? new Date(value) : null,
            },
          };
        },
      }),
    ],
  });
};
