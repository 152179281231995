<script setup lang="ts">
import { normalize } from '@/libs/helpers/strings';
import { ref, watch, type PropType } from 'vue';

const DEBOUNCE_MS = 400;

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },

  searchFields: {
    type: Array as PropType<String[]>,
    required: true,
  },

  searchList: {
    type: Array as PropType<Array<{ [key: string]: any }>>,
    required: true,
  },

  idKey: {
    type: String,
    required: false,
    default: '_id',
  },
});

const emit = defineEmits(['filteredList', 'update:hasInput']);

defineExpose({
  resetSearch,
});

const concatValuesMap = ref<Map<string, string>>(new Map());
const input = ref<string>('');
const timeoutID = ref<NodeJS.Timeout | null>(null);
const timer = ref<NodeJS.Timeout | null>(null);

watch(input, () => {
  triggerResearch();
});

watch(
  () => props.searchList,
  () => {
    if (props.searchList?.length > 0) {
      createConcatValuesMap();
    }
  },
  { deep: true, immediate: true },
);

function triggerResearch() {
  // If a timeout job is already started, cancel it and start a new one with the last input
  if (timeoutID.value) {
    clearTimeout(timeoutID.value);
  }
  // Trigger timeout after 500ms
  timeoutID.value = setTimeout(() => {
    search();
  }, 500);

  if (input.value.length > 0) {
    emit('update:hasInput', true);
  } else {
    emit('update:hasInput', false);
  }
}

function handleInput() {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = setTimeout(search, DEBOUNCE_MS);
}

function search() {
  if (input.value.length === 0 || !concatValuesMap.value) {
    emit('filteredList', props.searchList);
  } else {
    const inputs = normalize(input.value)?.split(' ');
    const filteredList = props.searchList.reduce((acc: Object[], elem) => {
      // search every 'inputs' (spaced by ' ') in values to look for searched words
      const element = concatValuesMap.value.get(elem[props.idKey]);
      if (inputs?.every(input => element?.includes(input))) acc.push(elem);
      return acc;
    }, []);
    timeoutID.value = null;
    emit('filteredList', filteredList);
  }
}

/**
 * Create a concateneted string of all the fields that will be searched for each object and store it in a Map with the object id
 */
function createConcatValuesMap() {
  concatValuesMap.value = new Map();
  props.searchList.forEach(element => {
    const concatValues = props.searchFields
      .reduce((acc: string[], field) => {
        if (element[field as keyof Object]) {
          const normalizedString = normalize(element[field as keyof Object]);
          if (normalizedString) {
            acc.push(normalizedString);
          }
        }
        return acc;
      }, [])
      .join(' ');
    concatValuesMap.value.set(element[props.idKey], concatValues);
  });
}

/**
 * Reset the search bar, used from outside components
 */
// eslint-disable-next-line no-unused-vars
function resetSearch() {
  input.value = '';
}
</script>

<template>
  <div class="table-search-bar">
    <v-icon size="small" class="table-search-bar__icon">fa:fas fa-search</v-icon>
    <input
      v-model="input"
      class="table-search-bar__input"
      :class="disabled ? 'table-search-bar__input--disabled' : ''"
      :disabled="disabled"
      :placeholder="$t('search')"
      type="text"
      @input="handleInput"
    />
  </div>
</template>

<style lang="scss">
.table-search-bar {
  position: relative;

  &__icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  &__input {
    width: 100%;
    height: 44px;
    padding: 4px 10px 4px 36px;
    border: 1px solid $border;
    border-radius: 5px;
    background-color: $canvas;
    box-shadow: none;
    font: inherit;

    &--disabled {
      opacity: 0.6;
    }

    &:focus {
      border-color: $text-dark-variant;
      box-shadow: none;
    }

    &::placeholder {
      color: $text-neutral;
    }
  }
}
</style>
