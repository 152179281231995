<template>
  <v-container fluid class="list-versions">
    <DataGridVuetify
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('versionsList.title', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="sortedVersionItems"
      :loading="loading"
      :datagrid="datagrid"
      :is-admin-view="true"
    />

    <DetailModal v-if="dialog" :version="selectedVersion" @close="dialog = false" />
  </v-container>
</template>

<script>
import { devices } from '@/api.js';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import DetailModal from './DetailModal.vue';
import { getDatagrid } from './ListVersions.conf.js';

export default {
  name: 'VersionsList',
  components: {
    DataGridVuetify,
    DetailModal,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      selectedVersion: null,
      renderedDataLength: null,
      datagrid: getDatagrid(),
      versionItems: [],
    };
  },
  computed: {
    buildCellInjectors() {
      return {
        version: ({ apiData }) => ({
          click: () => this.selectVersion(apiData),
        }),
      };
    },
    sortedVersionItems() {
      return this.versionItems.slice().sort((a, b) => {
        const aVersion = this.formatSemverForSorting(a.version);
        const bVersion = this.formatSemverForSorting(b.version);

        const mainComparison = bVersion.sortable.localeCompare(aVersion.sortable, undefined, {
          numeric: true,
        });

        if (mainComparison === 0) {
          return bVersion.suffix.localeCompare(aVersion.suffix);
        }

        return mainComparison;
      });
    },
  },
  async created() {
    await this.fetchGroupsAndDevices();
  },
  methods: {
    formatSemverForSorting(version) {
      const [baseVersion, suffix] = version.split('-');
      const [major, minor = '0', patch = '0'] = baseVersion.split('.');

      const majorNum = major.padStart(5, '0');
      const minorNum = minor.padStart(5, '0');
      const patchNum = patch.padStart(5, '0');

      return {
        sortable: `${majorNum}.${minorNum}.${patchNum}`,
        suffix: suffix || '',
      };
    },
    async fetchGroupsAndDevices() {
      this.loading = true;
      try {
        const groupsWithDevices = await devices.getAllDeviceVersions();
        this.processDeviceVersions(groupsWithDevices);
      } catch (error) {
        console.error('Cant Retrieve devices', error);
      }
      this.loading = false;
    },
    processDeviceVersions(groupsWithDevices) {
      const versionCounts = Object.keys(groupsWithDevices).reduce((acc, groupId) => {
        const group = groupsWithDevices[groupId];
        group.device_versions.forEach(device => {
          const version = device.app_version;
          if (version) {
            if (!acc[version]) {
              acc[version] = { count: 0, groups: {} };
            }
            acc[version].count += 1;
            if (!acc[version].groups[group.name]) {
              acc[version].groups[group.name] = {
                group_id: groupId,
                count: 0,
              };
            }
            acc[version].groups[group.name].count += 1;
          }
        });
        return acc;
      }, {});

      const sortedVersions = Object.keys(versionCounts)
        .sort((a, b) =>
          this.formatSemverForSorting(a).sortable.localeCompare(
            this.formatSemverForSorting(b).sortable,
            undefined,
            { numeric: true },
          ),
        )
        .reverse();

      this.versionItems = sortedVersions.map(version => ({
        version,
        total: versionCounts[version].count.toString(),
        groups: Object.keys(versionCounts[version].groups).map(groupName => ({
          group: groupName,
          count: versionCounts[version].groups[groupName].count.toString(),
          group_id: versionCounts[version].groups[groupName].group_id, // Passer le group_id
        })),
      }));
    },
    selectVersion(item) {
      this.selectedVersion = this.versionItems.find(v => v.version === item.version);
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-versions {
  padding: 0;
}
</style>

<i18n locale="fr">
{
  "versionsList": {
    "title": "Versions",
  }
}
</i18n>

<i18n locale="en">
{
  "versionsList": {
    "title": "Versions",
  }
}
</i18n>
