<script setup>
import OptionMenuDropdown from '@/components/layout/NavBarTop/OptionMenuDropdown.vue';
import SearchBox from '@/components/common/SearchBox.vue';
import Breadcrumbs from '@/components/layout/NavBarTop/Breadcrumbs.vue';
import Language from '@/components/layout/NavBarTop/Language.vue';
import SelectGroup from '@/components/layout/NavBarTop/SelectGroup.vue';
import { Permission } from '@/auth';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

/** @return {boolean} */
const hasSearchPermission = computed(() => {
  return store.getters.hasPermission(Permission.RESEARCH);
});
</script>
<template>
  <div class="navbar">
    <div class="navbar__left">
      <SelectGroup />
      <Breadcrumbs />
    </div>
    <div class="navbar__right">
      <SearchBox v-if="hasSearchPermission" />
      <Language />
      <v-divider class="navbar__divider" vertical></v-divider>
      <OptionMenuDropdown />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navbar {
  $navbar-content-height: 36px;

  display: flex;
  gap: 12px;
  padding: $view-standard-padding;
  border-bottom: 1px solid $light-border;
  background-color: $canvas;

  &__divider {
    height: 24px;
  }

  &__left {
    display: flex;
    flex-grow: 2;
    height: $navbar-content-height;
  }

  &__right {
    display: flex;
    gap: 8px;
    height: $navbar-content-height;
  }
}
</style>
