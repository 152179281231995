<script setup lang="ts">
import { computed, ref, watch, type PropType } from 'vue';

import Btn from '@/components/ui/Btn.vue';
import DrawShapeMode from '@/libs/mapbox/draw-shape-mode';
import { getDrawTheme } from '@/libs/mapbox/draw-theme.js';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { DrawStep, useTripUpdates, type StopAwayFromShape } from '@/store-pinia/trip-updates';
import { MapboxHelper } from './mapboxHelper';
import cloneDeep from 'clone-deep';
import { lineString, point, nearestPointOnLine } from '@turf/turf';
import { useStore } from 'vuex';

const tripUpStore = useTripUpdates();
const store = useStore();

const mapboxDraw = ref<MapboxDraw>();
const prevent = (e: { preventDefault: () => any }) => e.preventDefault();

defineExpose({
  close,
  saveShape,
});

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },
  baseShape: {
    type: Object as PropType<Array<[number, number]>>,
    required: true,
  },
  routeColor: {
    type: String,
    default: '#ff0',
  },
});

const isReady = ref<boolean>(false);

setTimeout(() => {
  isReady.value = true;
}, 600);

const coordsInEdition = ref<Array<[number, number]>>();
const coordsSaved = ref<Array<[number, number]>>([]);

const stopZoneRadiusExceptions = computed<{ [stopId: string]: number }>(
  () => store.getters.group.stop_distance_threshold_exceptions,
);
const stopZoneRadiusDefault = computed<number>(() => store.getters.group.stop_distance_threshold || 50);

const emit = defineEmits(['canSaveShape']);

watch(
  () => props.baseShape,
  async (_, oldEditedShape) => {
    // Stop edition
    if (props.baseShape === null) {
      props.map.off('contextmenu', prevent);
      if (mapboxDraw.value) {
        props.map.removeControl(mapboxDraw.value as any);
      }
      return;
    }
    props.map.on('contextmenu', prevent);

    // Init draw control and callbacks on first use
    if (!mapboxDraw.value) {
      mapboxDraw.value = new MapboxDraw({
        displayControlsDefault: false,
        boxSelect: true,
        styles: getDrawTheme(props.routeColor),
        modes: {
          simple_select: MapboxDraw.modes.simple_select,
          draw_shape: DrawShapeMode,
        },
      });

      // Shape changed
      props.map.on('draw.update', e => {
        if (e.action === 'change_coordinates') {
          tripUpStore.currentStep = DrawStep.DRAW_SHAPE_DONE;
          coordsInEdition.value = e.features[0].geometry.coordinates;
        }
        if (e.action === 'select_path_to_edit') {
          tripUpStore.currentStep = DrawStep.DRAW_SHAPE;
        }
      });
    }

    if (!oldEditedShape) {
      props.map.addControl(mapboxDraw.value as any, 'top-left');
    }
  },
  { immediate: true },
);

watch([() => coordsSaved.value, () => tripUpStore.currentStep], () => {
  emit('canSaveShape', coordsSaved.value.length > 0 && tripUpStore.currentStep === DrawStep.SELECT_PATH);
});

watch(
  () => props.baseShape,
  () => {
    if (!mapboxDraw.value || !props.baseShape) return;
    mapboxDraw.value.changeMode('draw_shape', props.baseShape);
  },
  { immediate: true },
);

function resetToGtfsState() {
  updateShapeAndSetFirstStep(tripUpStore.initialTripShape);
  coordsSaved.value = cloneDeep(tripUpStore.initialTripShape);
}

function resetNonsavedPart() {
  const shapeToLoad = coordsSaved.value.length > 0 ? coordsSaved.value : props.baseShape;
  updateShapeAndSetFirstStep(shapeToLoad);
}

function confirmDeviationPart() {
  if (coordsInEdition.value) coordsSaved.value = cloneDeep(coordsInEdition.value);
  updateShapeAndSetFirstStep(coordsInEdition.value);
}

function updateShapeAndSetFirstStep(shape: [number, number][] | undefined) {
  if (mapboxDraw.value && shape) {
    mapboxDraw.value.changeMode('draw_shape', shape);
    tripUpStore.currentStep = DrawStep.SELECT_PATH;
    coordsInEdition.value = undefined;
  }
}

watch(
  [() => props.baseShape, () => coordsInEdition.value, () => coordsSaved.value],
  () => {
    // Case 1 : coordsInEdition if exist, case 2:  coordsSaved, else default coords from props
    const shapeToTest =
      coordsInEdition.value && coordsInEdition.value?.length > 0
        ? coordsInEdition.value
        : coordsSaved.value.length > 0
          ? coordsSaved.value
          : props.baseShape;

    const stopIdsAwayFromShape = [] as StopAwayFromShape[];
    if (shapeToTest) {
      // Check every stops & detect if they are too far away from shape
      tripUpStore.getAllStops.forEach(stop => {
        const distanceToCheck = stopZoneRadiusExceptions.value[stop.stop_id] || stopZoneRadiusDefault.value;
        const result = findNearestDistanceBetweenPointAndShape(shapeToTest, [stop.stop_lon, stop.stop_lat]);
        if (result && result > distanceToCheck) {
          stopIdsAwayFromShape.push({
            stopId: stop.stop_id,
            distanceThreshold: distanceToCheck,
            currentDistance: Math.ceil(result),
          });
        }
      });
    }
    tripUpStore.stopsAwayFromShape = stopIdsAwayFromShape;
  },
  { immediate: true },
);

function saveShape() {
  tripUpStore.editedShape = coordsSaved.value;
  close();
}

function close() {
  tripUpStore.inShapeEdition = false;
  props.map.removeControl(mapboxDraw.value as any);
  emit('canSaveShape', false);

  // Handle layers orders on specific edition case
  setTimeout(() => {
    MapboxHelper.defaultLayerOrderReset(props.map);
  }, 300);
}

/**
 * Find the nearest distance between a point and a portion of a shape
 */
function findNearestDistanceBetweenPointAndShape(
  coords: Array<[number, number]>,
  longlat: [number, number],
): null | number {
  let nearestLength: null | number = null;
  coords.forEach((wp, index) => {
    if (index !== 0) {
      const line = lineString([coords[index - 1], wp]);
      const snapped = nearestPointOnLine(line, point(longlat), { units: 'kilometers' });
      const l = snapped.properties.dist;
      if (nearestLength === null || l < nearestLength) {
        nearestLength = l;
      }
    }
  });
  // convert  to meters
  return nearestLength ? nearestLength * 1000 : null;
}
</script>

<template>
  <div class="mapbox-shape-edition">
    <Transition name="fade">
      <v-card v-if="isReady" elevation="0" class="mapbox-shape-edition__card">
        <v-card-item class="mapbox-shape-edition__card__header">
          <span class="mapbox-shape-edition__card__title">
            <v-icon size="x-small" class="mr-2">fa:fas fa-pen</v-icon>
            {{ $t('shapeEdition') }}
          </span>
          <Btn
            type="secondary"
            :disabled="tripUpStore.currentStep !== DrawStep.SELECT_PATH"
            @click="resetToGtfsState()"
          >
            <font-awesome-icon icon="fa-rotate-right" />
            <span>{{ $t('resetShape') }}</span>
          </Btn>
        </v-card-item>

        <v-divider class="mt-0 mb-0" />
        <div class="mapbox-shape-edition__card__steps">
          <v-card-item
            class="mapbox-shape-edition__card__step"
            :class="{
              'mapbox-shape-edition__card__step-valid': tripUpStore.currentStep > DrawStep.SELECT_PATH,
            }"
          >
            <img
              v-if="tripUpStore.currentStep === DrawStep.SELECT_PATH"
              alt="step1"
              src="@/assets/img/shape-edition-step1.png"
            />
            <img v-else alt="stepValid" src="@/assets/img/shape-edition-valid.png" />
            <div class="mapbox-shape-edition__card__step__text">
              <span class="mapbox-shape-edition__card__step__title">
                {{ $t('shapeEditionSteps.step1.text') }}
              </span>
              <span>{{ $t('shapeEditionSteps.step1.subText') }}</span>
            </div>
          </v-card-item>

          <Transition name="fade">
            <v-card-item
              v-if="tripUpStore.currentStep > DrawStep.SELECT_PATH"
              class="mapbox-shape-edition__card__step"
              :class="{
                'mapbox-shape-edition__card__step-valid': tripUpStore.currentStep > DrawStep.DRAW_SHAPE,
              }"
            >
              <img
                v-if="tripUpStore.currentStep === DrawStep.DRAW_SHAPE"
                alt="step1"
                src="@/assets/img/shape-edition-step2.png"
              />
              <img v-else alt="stepValid" src="@/assets/img/shape-edition-valid.png" />
              <div class="mapbox-shape-edition__card__step__text">
                <span class="mapbox-shape-edition__card__step__title">
                  {{ $t('shapeEditionSteps.step2.text') }}
                </span>
                <span>{{ $t('shapeEditionSteps.step2.subText') }}</span>
              </div>
            </v-card-item>
          </Transition>
          <Transition name="fade">
            <v-card-item
              v-if="tripUpStore.currentStep === DrawStep.DRAW_SHAPE_DONE"
              class="mapbox-shape-edition__card__step mapbox-shape-edition__card__step-tip"
            >
              <v-icon>fa:fas fa-info-circle</v-icon>
              <div class="mapbox-shape-edition__card__step__text">
                <span>
                  {{ $t('shapeEditionSteps.stepTip.text1') }}
                  <b>{{ $t('shapeEditionSteps.stepTip.bold1') }}</b>
                </span>
                <span>
                  {{ $t('shapeEditionSteps.stepTip.text2') }}
                  <b>{{ $t('shapeEditionSteps.stepTip.bold2') }}</b>
                </span>
              </div>
            </v-card-item>
          </Transition>
        </div>
        <v-divider class="mt-0 mb-0" />

        <v-card-actions v-if="tripUpStore.currentStep !== DrawStep.SELECT_PATH">
          <Btn type="secondary" @click="resetNonsavedPart()">
            <span>{{ $t('deselect') }}</span>
          </Btn>
          <Btn
            type="primary"
            :disabled="tripUpStore.currentStep !== DrawStep.DRAW_SHAPE_DONE"
            @click="confirmDeviationPart()"
          >
            <font-awesome-icon icon="fa-check" />
            <span>{{ $t('confirm') }}</span>
          </Btn>
        </v-card-actions>
      </v-card>
    </Transition>
  </div>
</template>

<style lang="scss">
.mapbox-shape-edition {
  img {
    width: 48px;
    height: 48px;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__card {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 10;
    display: block;
    width: 380px;
    border-radius: 12px;

    &__header {
      padding: 12px 16px;

      .v-card-item__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__step {
      padding: 12px 12px 0;
      color: $text-dark-variant;
      font-weight: 500;
      font-size: 12px;

      .v-card-item__content {
        display: flex;
        gap: 16px;
      }

      &__text {
        display: flex;
        flex-direction: column;
      }

      &__title {
        font-weight: 600;
      }
    }

    &__steps {
      padding-bottom: 12px;
    }

    &__step-valid {
      .mapbox-shape-edition__card__step__text {
        opacity: 0.5;
      }
    }

    &__step-tip {
      padding: 4px 12px 0;

      .v-card-item__content {
        padding: 8px 12px;
        background-color: #f5f5f5;

        i {
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    button {
      font-size: 14px;
    }

    &__title {
      font-weight: 600;
      font-size: 14px;

      i {
        margin-top: -4px;
      }
    }
  }

  .v-card-actions {
    padding: 12px;

    button {
      width: 100%;
    }
  }
}
</style>

<i18n locale="fr">
{
  "shapeEdition": "Edition de tracé",
  "resetShape": "Réinitialiser le tracé",
  "deselect": "Désélectionner",
  "shapeEditionSteps": {
    "step1": {
      "text": "Cliquez sur 1 ou 2 points du tracé",
      "subText": "pour sélectionner une portion à modifier."
    },
    "step2": {
      "text": "Cliquez plusieurs fois sur la carte",
      "subText": "pour définir des coordonnées alternatives."
    },
    "stepTip": {
      "text1": "Déplacez-les en les",
      "bold1": "faisant glisser.",
      "text2": "Supprimez-les avec le ",
      "bold2": "clic droit.",
    }
  }
}
</i18n>

<i18n locale="en">
{
  "shapeEdition": "Shape edition",
  "resetShape": "Reset shape",
  "deselect": "Deselect",
  "shapeEditionSteps": {
    "step1": {
      "text": "Click on 1 or 2 points of the path",
      "subText": "to select a portion to modify."
    },
    "step2": {
      "text": "Click on the map several times",
      "subText": "to define alternatives coordinates."
    },
    "stepTip": {
      "text1": "Displace new coordinates by",
      "bold1": "dragging.",
      "text2": "Delete new coordinates with",
      "bold2": "right click.",
    }
  }
}
</i18n>
