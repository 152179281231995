/** safe get localstorage as a Map object */
function safeGetLsAsMapObj(lsName: string) {
  try {
    const lsItem = localStorage.getItem(lsName);
    if (lsItem) return new Map(JSON.parse(lsItem));
    return new Map();
  } catch (e) {
    return new Map();
  }
}

export { safeGetLsAsMapObj };
