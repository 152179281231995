<script setup lang="ts">
import { nextTick, ref, watch, type PropType } from 'vue';

import { normalize } from '@/libs/helpers/strings';

const DEBOUNCE_MS: number = 400;

const concatValuesMap = ref<Map<string, string>>(new Map());
const isInputFocused = ref<boolean>(false);
const isSearchBarExtended = ref<boolean>(false);
const input = ref<string>('');
const timeoutID = ref<NodeJS.Timeout | null>(null);
const timer = ref<NodeJS.Timeout | null>(null);

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },

  searchFields: {
    type: Array as PropType<String[]>,
    required: true,
  },

  searchList: {
    type: Array as PropType<
      {
        [key: string]: string;
      }[]
    >,
    required: true,
  },

  idKey: {
    type: String,
    required: false,
    default: '_id',
  },

  showIconOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['filteredList', 'update:hasInput']);

defineExpose({
  resetSearch,
});

watch(input, () => {
  triggerResearch();
});

watch(
  () => props.searchList,
  () => {
    if (props.searchList?.length > 0) {
      createConcatValuesMap();
    }
  },
  { deep: true, immediate: true },
);

function triggerResearch() {
  // If a timeout job is already started, cancel it and start a new one with the last input
  if (timeoutID.value) {
    clearTimeout(timeoutID.value);
  }
  // Trigger timeout after 500ms
  timeoutID.value = setTimeout(() => {
    search();
  }, 500);

  if (input.value.length > 0) {
    emit('update:hasInput', true);
  } else {
    emit('update:hasInput', false);
  }
}

function handleInput() {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = setTimeout(search, DEBOUNCE_MS);
}

function search() {
  if (input.value.length === 0 || !concatValuesMap.value) {
    emit('filteredList', null);
  } else {
    const inputs = normalize(input.value)?.split(' ');
    const filteredList = props.searchList.reduce((acc: String[], elem: { [key: string]: string }) => {
      // search every 'inputs' (spaced by ' ') in values to look for searched words
      const element = concatValuesMap.value.get(elem[props.idKey as keyof Object]);
      if (inputs?.every(input => element?.includes(input))) {
        if (elem && elem[props.idKey as keyof Object]) {
          acc.push(elem[props.idKey as keyof Object]);
        }
      }
      return acc;
    }, []);
    timeoutID.value = null;
    emit('filteredList', filteredList);
  }
}

/**
 * Create a concateneted string of all the fields that will be searched for each object and store it in a Map with the object id
 */
function createConcatValuesMap() {
  concatValuesMap.value = new Map();
  props.searchList?.forEach(element => {
    const concatValues = props.searchFields
      .reduce((acc: string[], field: String) => {
        if (element[field as keyof Object]) {
          const normalizedString: string | null = normalize(element[field as keyof Object]);
          if (normalizedString) {
            acc.push(normalizedString);
          }
        }
        return acc;
      }, [])
      .join(' ');
    concatValuesMap.value.set(element[props.idKey], concatValues);
  });
}

/**
 * Reset the search bar
 */
function resetSearch() {
  input.value = '';
  if (props.showIconOnly) {
    isSearchBarExtended.value = false;
  }
}

function extendSearchBar() {
  if (props.showIconOnly) {
    isSearchBarExtended.value = true;
    setTimeout(() => document.getElementById('searchBarInput')?.focus(), 50);
  }
}

function onClickOnXmark() {
  resetSearch();
  isInputFocused.value = false;
}

async function focusInputOnFocus() {
  await nextTick();
  isInputFocused.value = true;
}

async function unfocusInputOnBlur() {
  if (!input.value) {
    isInputFocused.value = false;
    if (props.showIconOnly) isSearchBarExtended.value = false;
  } else {
    await nextTick();
    if (!input.value) isInputFocused.value = false;
  }
}
</script>

<template>
  <div class="table-search-bar-v2">
    <v-icon
      class="table-search-bar-v2__icon"
      :class="{ 'table-search-bar-v2__icon-only': showIconOnly && !isSearchBarExtended }"
      @click="extendSearchBar"
    >
      fa:fas fa-search
    </v-icon>
    <input
      v-if="!showIconOnly || isSearchBarExtended"
      id="searchBarInput"
      v-model="input"
      class="table-search-bar-v2__input"
      :class="disabled ? 'table-search-bar-v2__input--disabled' : ''"
      :disabled="disabled"
      :placeholder="$t('search')"
      type="text"
      @focus="focusInputOnFocus()"
      @blur="unfocusInputOnBlur()"
      @input="handleInput"
    />
    <font-awesome-icon
      v-if="isInputFocused"
      icon="fa-xmark"
      class="table-search-bar-v2__xmark"
      @click="onClickOnXmark()"
    />
  </div>
</template>

<style lang="scss">
.table-search-bar-v2 {
  position: relative;
  height: 32px;

  &__icon {
    top: 25%;
    left: 30px;
    transform: translate(0, -65%);

    &.v-icon--size-default {
      font-size: 14px;
    }
  }

  &__icon-only {
    top: 50%;
    left: 0;
    cursor: pointer;
    padding-inline: 12px;
  }

  &__input {
    width: 130px;
    height: 32px;
    padding: 4px 10px 4px 35px;
    border: none;
    border-radius: 4px;
    font: inherit;
    font-size: 14px;

    &--disabled {
      opacity: 0.6;
    }

    &:hover:not(:focus, :disabled) {
      background: $background-variant;
    }

    &:not(:focus) {
      &::placeholder {
        color: $text-dark;
        font-weight: 500;
        text-align: center;
      }
    }

    &:focus,
    &:not(:placeholder-shown) {
      width: 170px;
      border: 1px solid $border-variant;
      box-shadow: none;
      transition-duration: 0.2s;
      transition-property: all;
    }
  }

  &__xmark {
    position: absolute;
    top: 50%;
    right: 8px;
    color: $text-neutral;
    font-size: 14px;
    cursor: pointer;
    transform: translateY(-50%);
  }
}
</style>
