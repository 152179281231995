import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { getISODate, dateGtfsFormatToObj } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';

const RouteBadge = defineAsyncComponent(() => import('@/components/common/RouteBadge.vue'));
const ClickableCell = defineAsyncComponent(() => import('@/components/Table/cells/ClickableCell.vue'));
const DateCell = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));

export const URGENCIES_LIST_LS_COLUMNS = 'urgenciesList/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  SENT: 'sendTime',
  RECEPTION: 'receptionTime',
  ACKNOWLEDGMENT_TIME: 'acknowledgmentTime',
  RESOLUTION: 'resolutionTime',
  ACKNOWLEDGMENT_OPERATOR: 'acknowledgmentOperator',
  RESOLUTION_OPERATOR: 'resolutionOperator',
  DRIVER: 'driver',
  DEVICE: 'device',
  TRIP_FORMATTED_NAME: 'tripFormattedName',
  ROUTE: 'routeShortName',
  IS_FALSE_ALERT: 'isFalseAlert',
  COMMENT: 'comment',
};

/** @enum {import('@/components/Table/DataGridVuetify/models/DataGrid.models').CellBuilder} */
const CellBuilders = {
  DATE([value, object]) {
    return {
      component: DateCell,
      props: {
        date: value,
      },
    };
  },
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(URGENCIES_LIST_LS_COLUMNS));

  // Helpers
  const defaultSelected = columnType => !ls || ls.includes(columnType);
  const defaultNotSelected = columnKey => ls && ls.includes(columnKey);

  return new DataGrid({
    name: 'urgenciesListDatagrid',
    searchFields: [
      'acknowledgmentOperator',
      'resolutionOperator',
      'driver',
      'device',
      'tripFormattedName',
      'routeShortName',
    ],
    defaultSortBy: [{ key: ColumnKey.RESOLUTION, order: SortOrder.DESC }],
    rowIdKey: 'id',
    columnSelectionLocalStorageKey: URGENCIES_LIST_LS_COLUMNS,
    hasActions: false,
    hasFirstRowSticky: false,
    columns: [
      // SENT
      new DataGridColumn({
        key: ColumnKey.SENT,
        title: 'column.sent',
        defaultSelected: defaultNotSelected(ColumnKey.SENT),
        cellBuilder: CellBuilders.DATE,
      }),

      // RECEPTION
      new DataGridColumn({
        key: ColumnKey.RECEPTION,
        title: 'column.reception',
        defaultSelected: defaultNotSelected(ColumnKey.RECEPTION),
        cellBuilder: CellBuilders.DATE,
      }),

      // ACKNOWLEDGMENT_TIME
      new DataGridColumn({
        key: ColumnKey.ACKNOWLEDGMENT_TIME,
        title: 'column.acknowledgmentTime',
        selectable: false,
        cellBuilder: CellBuilders.DATE,
      }),

      // RESOLUTION
      new DataGridColumn({
        key: ColumnKey.RESOLUTION,
        title: 'column.resolution',
        selectable: false,
        cellBuilder: CellBuilders.DATE,
      }),

      // ACKNOWLEDGMENT_OPERATOR
      new DataGridColumn({
        key: ColumnKey.ACKNOWLEDGMENT_OPERATOR,
        title: 'column.acknowledgmentOperator',
        selectable: false,
      }),

      // RESOLUTION_OPERATOR
      new DataGridColumn({
        key: ColumnKey.RESOLUTION_OPERATOR,
        title: 'column.resolutionOperator',
        selectable: false,
      }),

      // DRIVER
      new DataGridColumn({
        key: ColumnKey.DRIVER,
        title: 'column.driver',
        selectable: false,
      }),

      // DEVICE
      new DataGridColumn({
        key: ColumnKey.DEVICE,
        title: 'column.device',
        defaultSelected: defaultSelected(ColumnKey.DEVICE),
        cellBuilder([value, object]) {
          return {
            component: ClickableCell,
            props: {
              displayValue: value,
              link: {
                name: GroupRoute.DEVICE_DETAILLED,
                params: { deviceId: value },
              },
            },
          };
        },
      }),

      // ROUTE
      new DataGridColumn({
        key: ColumnKey.ROUTE,
        title: 'column.route',
        filterable: true,
        defaultSelected: defaultSelected(ColumnKey.ROUTE),
        cellBuilder([value, object]) {
          const route = object.route;
          return route
            ? {
                component: RouteBadge,
                props: {
                  route: {
                    route_color: route.route_color,
                    route_text_color: route.route_text_color,
                    route_short_name: route.route_short_name,
                  },
                  value,
                },
              }
            : null;
        },
      }),

      // TRIP FORMATTED NAME
      new DataGridColumn({
        key: ColumnKey.TRIP_FORMATTED_NAME,
        title: 'column.trip',
        defaultSelected: defaultSelected(ColumnKey.TRIP_FORMATTED_NAME),
        cellBuilder([value, object], { groupId }) {
          if (!object.trip) return;

          return {
            component: 'RouterLink',
            props: {
              to: {
                name: GroupRoute.TRIP_DETAILED,
                params: { groupId, tripId: object.trip.id },
                query: { date: getISODate(dateGtfsFormatToObj(object.trip.start_date)) },
              },
            },
            value,
          };
        },
      }),

      // IS FALSE ALERT
      new DataGridColumn({
        key: ColumnKey.IS_FALSE_ALERT,
        title: 'column.falseAlert',
        filterable: true,
        defaultSelected: defaultSelected(ColumnKey.IS_FALSE_ALERT),
      }),

      // COMMENT
      new DataGridColumn({
        key: ColumnKey.COMMENT,
        title: 'column.comment',
        defaultSelected: defaultSelected(ColumnKey.COMMENT),
      }),
    ],
  });
};
