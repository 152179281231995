<script setup lang="ts">
import mapboxgl, { LngLat, LngLatBounds } from 'mapbox-gl';
import { MapboxHelper } from '@/components/map/mapboxHelper';
import { createGeoJSONCircle } from '@/libs/helpers/geo';
import { AppUrl } from '@/api';
import { LocationType } from '@/store/gtfs.js';

import { INTERPOLATE } from './map-const.js';
import { GroupRoute } from '@/libs/routing';
import { computed, nextTick, onMounted, onUnmounted, ref, watch, type PropType } from 'vue';
import { useStore } from 'vuex';
import type { Stop, StopTime } from '@/@types/gtfs.js';
import i18n from '@/i18n.js';
import { useTripUpdates } from '@/store-pinia/trip-updates.js';
import {
  DEVIATION_STATION_LAYER,
  DEVIATION_STOP_LAYER,
  STATIONS_ICON_LAYER_ID,
  STATIONS_LABEL_LAYER,
  STOPS_ICON_LAYER_ID,
  STOPS_LABEL_LAYER,
  STOPS_ZONE_LAYER_ID,
  UNSERVED_STATION_LAYER,
  UNSERVED_STOP_LAYER,
  type MapStop,
  type StopsOptions,
} from '@/@types/mapbox.js';

const STATIONS_SOURCE_ID = 'stationsSource';
const STOPS_SOURCE_ID = 'stopsSource';
const STOPS_ZONE_SOURCE_ID = 'stopsZoneSource';

const { t, tc } = i18n.global;
const store = useStore();

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },

  gtfsId: {
    type: String,
    required: true,
  },

  options: {
    type: Object as PropType<StopsOptions>,
    required: true,
  },

  stops: {
    type: Array<MapStop>,
    required: true,
  },

  displayTooltip: {
    type: Boolean,
    default: false,
  },

  tripUpdateMode: {
    type: Boolean,
    default: false,
  },
});

const tripUpStore = props.tripUpdateMode ? useTripUpdates() : null;

const emit = defineEmits([
  'click',
  'mouseenter',
  'mouseleave',
  'update:bounds',
  'isLoaded',
  'tooltipToggleStop',
]);

const hoveredId = ref<string | null>(null);
const loaded = ref<Boolean>(false);

const groupId = computed(() => store.getters.group._id);

const allStopsFiltered = computed<Array<Stop>>(() => {
  if (!loaded.value) return [];

  let stopsGtfs: { [stopId: string]: Stop } = {};
  if (tripUpStore) {
    // if mapboxStops is displayed from trip updates, get stops source from a different store
    stopsGtfs = Object.fromEntries(tripUpStore.getAllStops);
  } else {
    stopsGtfs = store.getters['gtfs/getCachedGtfsTable'](props.gtfsId, 'stops');
  }

  const result = Object.values(
    props.stops.reduce((acc: { [stopId: string]: Stop }, elem) => {
      if (stopsGtfs[elem.id]) {
        acc[elem.id] = stopsGtfs[elem.id];
      }

      return acc;
    }, {}),
  );
  return result;
});

const bounds = computed<LngLatBounds | null>(() => {
  const list = allStopsFiltered.value;
  if (list.length === 0) return null;
  const bounds = new LngLatBounds();
  list.forEach(stop => bounds.extend(new LngLat(stop.stop_lon, stop.stop_lat)));

  return bounds;
});

const unservedStops = computed<{ [stop_sequence: string]: { stop_id: string } }>(() => {
  return props.stops.reduce(
    (acc: { [stop_sequence: string]: { stop_id: string } }, { id, unserved, stop_sequence }) => {
      if (unserved) {
        acc[stop_sequence] = {
          stop_id: id,
        };
      }
      return acc;
    },
    {},
  );
});

const highlightStops = computed<{ [stopId: string]: boolean }>(() => {
  return props.stops.reduce((acc: { [stopId: string]: boolean }, elem) => {
    if (elem.highlight) {
      acc[elem.id] = true;
    }

    return acc;
  }, {});
});

const stationsFiltered = computed<{ [stopId: string]: Stop }>(() => {
  return stopsByType.value[LocationType.STATION];
});

const stationsLabelLayer = computed<mapboxgl.Layer>(() => {
  return stopsLabelLayerByType(LocationType.STATION);
});

const stationsSource = computed<Array<GeoJSON.Feature<GeoJSON.Point>>>(() => {
  return stopsSourceByType(LocationType.STATION);
});

const stopsByType = computed<{ [locationType: string]: { [stopId: string]: Stop } }>(() => {
  const allStops = allStopsFiltered.value;

  return allStops.reduce(
    (acc: { [locationType: string]: { [stopId: string]: Stop } }, stop) => {
      const type = stop.location_type || LocationType.STOP;
      if (!acc[type]) acc[type] = {};
      acc[type][stop.stop_id] = stop;

      return acc;
    },
    { [LocationType.STATION]: {}, [LocationType.STOP]: {} },
  );
});

const stopsFiltered = computed<{ [stopId: string]: Stop }>(() => {
  return stopsByType.value[LocationType.STOP];
});

const stopsLabelLayer = computed<mapboxgl.Layer>(() => {
  return stopsLabelLayerByType(LocationType.STOP);
});

const stopsSource = computed<Array<GeoJSON.Feature<GeoJSON.Point>>>(() => {
  return stopsSourceByType(LocationType.STOP);
});

const stopZoneRadiusExceptions = computed<{ [stopId: string]: number }>(() => {
  return store.getters.group.stop_distance_threshold_exceptions || {};
});

const stopZoneRadiusDefault = computed<number>(() => store.getters.group.stop_distance_threshold);

const stopsZoneSource = computed<Array<GeoJSON.Feature<GeoJSON.Polygon>>>(() => {
  const zones = !props.options.stopsZones ? [] : allStopsFiltered.value;

  return zones.map(stop => ({
    type: 'Feature',

    geometry: createGeoJSONCircle(
      [stop.stop_lon, stop.stop_lat],
      stopZoneRadiusExceptions.value[stop.stop_id] || stopZoneRadiusDefault.value,
    ),

    properties: {
      id: stop.stop_id,
    },
  }));
});

watch(
  bounds,
  (newBounds, oldBounds) => {
    // only apply update on bounds if not in tripUpdateMode & only if bounds has changed
    // case tripUpdateMode : handled in TripModificationPage index
    if (!props.tripUpdateMode && newBounds && (!oldBounds || newBounds.toString() !== oldBounds.toString())) {
      emit('update:bounds', bounds.value);
    }
  },
  { immediate: true },
);

watch(
  stopsSource,
  () => {
    MapboxHelper.updateSource(props.map, STOPS_SOURCE_ID, stopsSource.value);
  },
  { deep: true },
);

watch(
  stopsSource,
  () => {
    MapboxHelper.updateSource(props.map, STATIONS_SOURCE_ID, stationsSource.value);
  },
  { deep: true },
);

watch(
  stopsZoneSource,
  () => {
    MapboxHelper.updateSource(props.map, STOPS_ZONE_SOURCE_ID, stopsZoneSource.value);
  },
  { deep: true },
);

watch(
  () => tripUpStore?.inAddManualStopMode,
  () => {
    if (tripUpStore?.inAddManualStopMode) {
      props.map.getCanvasContainer().style.cursor = 'crosshair';
    } else {
      props.map.getCanvasContainer().style.cursor = '';
    }
  },
);

onMounted(() => {
  props.map.once('idle', () => {
    loaded.value = true;
  });
  initSourceAndLayer();
});

onUnmounted(() => {
  MapboxHelper.cleanLayersAndSources(
    props.map,
    [
      STATIONS_LABEL_LAYER,
      STATIONS_ICON_LAYER_ID,
      STOPS_LABEL_LAYER,
      STOPS_ICON_LAYER_ID,
      STOPS_ZONE_LAYER_ID,
    ],
    [STATIONS_SOURCE_ID, STOPS_SOURCE_ID, STOPS_ZONE_SOURCE_ID],
  );
});

function addStationsIconLayer() {
  createStopOrStationLayer(STATIONS_ICON_LAYER_ID, STATIONS_SOURCE_ID);
  addLayerActions(STATIONS_ICON_LAYER_ID);
}

function addStopsIconLayer() {
  createStopOrStationLayer(STOPS_ICON_LAYER_ID, STOPS_SOURCE_ID);
  addLayerActions(STOPS_ICON_LAYER_ID);
}

function addStopsZoneLayer() {
  MapboxHelper.addLayer(props.map, {
    id: STOPS_ZONE_LAYER_ID,
    type: 'fill',
    source: STOPS_ZONE_SOURCE_ID,
    paint: {
      'fill-color': '#ff0000',
      'fill-opacity': 0.35,
    },
  });
}

function addStopsLabelLayer() {
  MapboxHelper.addLayer(props.map, stopsLabelLayer.value);
}

function addStationsLabelLayer() {
  MapboxHelper.addLayer(props.map, stationsLabelLayer.value);
}

function addTripUpdateLayers() {
  createUnservedStopOrStationLayer(UNSERVED_STOP_LAYER, STOPS_SOURCE_ID);
  createUnservedStopOrStationLayer(UNSERVED_STATION_LAYER, STATIONS_SOURCE_ID);
  createDeviationStopOrStationLayer(DEVIATION_STOP_LAYER, STOPS_SOURCE_ID);
  createDeviationStopOrStationLayer(DEVIATION_STATION_LAYER, STATIONS_SOURCE_ID);
}

function initSourceAndLayer() {
  MapboxHelper.createEmptySource(props.map, STATIONS_SOURCE_ID);
  MapboxHelper.createEmptySource(props.map, STOPS_SOURCE_ID);
  MapboxHelper.createEmptySource(props.map, STOPS_ZONE_SOURCE_ID);

  addStopsZoneLayer();
  addStopsIconLayer();
  addStationsIconLayer();
  addStopsLabelLayer();
  addStationsLabelLayer();

  if (props.options.showUnserved) {
    addTripUpdateLayers();
  }

  emit('isLoaded', true);
}

function addLayerActions(layerName: string) {
  props.map.on('mouseenter', layerName, e => {
    if (e.features && e.features.length > 0 && !tripUpStore?.inAddManualStopMode) {
      emit('mouseenter', e);
    }
  });
  props.map.on('mouseleave', layerName, e => {
    if (!tripUpStore?.inAddManualStopMode) {
      onStopMouseLeave(e, layerName);
    }
  });
  props.map.on('mousemove', layerName, e => {
    if (e.features && e.features.length > 0 && !tripUpStore?.inAddManualStopMode) {
      onStopMouseMove(e, layerName);
    }
  });
  props.map.on('click', layerName, e => {
    if (e.features && e.features.length > 0) {
      emit('click', e);
      if (props.displayTooltip) {
        MapboxHelper.displayTooltipOnClick(e, props.map, 'tooltip-map');
      }
      if (props.options.stopSelectorData && props.options.stopSelectorData?.length > 0) {
        displayStopSelectorOnClick(e, props.map, props.options.stopSelectorData);
      }
    }
  });
  props.map.on('click', e => {
    if (tripUpStore?.inAddManualStopMode) {
      if (e.lngLat.lat && e.lngLat.lng) {
        if (tripUpStore?.stopInEdition) {
          tripUpStore.stopInEdition.stop_lat = e.lngLat.lat;
          tripUpStore.stopInEdition.stop_lon = e.lngLat.lng;
        }
      }
    }
  });
}

/**
 * Generic method to create stopsLabel by type
 */
function stopsLabelLayerByType(type: import('@/store/gtfs.js').LocationType): mapboxgl.Layer {
  const isStation = LocationType.STATION === type;

  const id = isStation ? STATIONS_LABEL_LAYER : STOPS_LABEL_LAYER;
  const sourceId = isStation ? STATIONS_SOURCE_ID : STOPS_SOURCE_ID;

  return {
    id,
    type: 'symbol',
    source: sourceId,
    filter: ['any', ['==', ['get', 'id'], hoveredId.value]],

    layout: {
      'text-allow-overlap': true,
      'text-anchor': 'top-left',
      'text-field': ['get', 'label'],
      'text-justify': 'left',
      'text-offset': [0.8, -0.4],
    },

    paint: {
      'text-halo-color': '#ffffff',
      'text-halo-width': 3,
    },
  };
}

function stopsSourceByType(
  type: import('@/store/gtfs.js').LocationType,
): Array<GeoJSON.Feature<GeoJSON.Point>> {
  const isStation = LocationType.STATION === type;

  const markers = isStation
    ? !props.options.stationsMarkers
      ? []
      : Object.values(stationsFiltered.value)
    : !props.options.stopsMarkers
      ? []
      : Object.values(stopsFiltered.value);

  return markers.map(stop => ({
    type: 'Feature',

    geometry: {
      type: 'Point',
      coordinates: [stop.stop_lon, stop.stop_lat],
    },

    properties: {
      highlight: highlightStops.value[stop.stop_id],
      unserved: !!Object.values(unservedStops.value).find(st => st.stop_id === stop.stop_id),
      deviation: !!Object.values(props.stops).find(st => st.id === stop.stop_id && st.deviation),
      editionHighlight: !!Object.values(props.stops).find(
        st => st.id === stop.stop_id && st.editionHighlight,
      ),
      id: stop.stop_id,
      label: stop.stop_name,
      description: generateStopDescription(stop),
    },
  }));
}

function onStopMouseLeave(event: mapboxgl.MapLayerMouseEvent, layerName: string) {
  emit('mouseleave', event);
  hoveredId.value = null;
  setLabelFilterUpdate(layerName);
}

function onStopMouseMove(event: mapboxgl.MapLayerMouseEvent, layerName: string) {
  if (event?.features && event.features.length > 0) {
    const stopId = event.features[0]?.properties?.id;

    if (hoveredId.value !== stopId) {
      hoveredId.value = stopId;
    }
    setLabelFilterUpdate(layerName);
  }
}

/**
 * Generate stop tooltip HTML based on stop informations
 */
function generateStopDescription(stop: Stop) {
  const tooltipCtaUrl = `${AppUrl}/#/${groupId.value}/${GroupRoute.STOP_DETAILED}/${stop.stop_id}`;

  // Stop name
  let description = `<span class="tooltip-map__title-text">${stop.stop_name}</span>`;
  // Parent station if exists
  if (stop.parent_station && stationsFiltered.value[stop.parent_station]) {
    const relatedStationName = stationsFiltered.value[stop.parent_station]?.stop_name;
    description += `<span class="tooltip-map__sub-text">${relatedStationName}</span>`;
  }
  // CTA button
  description += `<a class="tooltip-map__button" href="${tooltipCtaUrl}">${t(
    'detail',
  )} <i class="fas fa-arrow-right ui-btn__redirect-icon"/></a>`;
  return description;
}

function setLabelFilterUpdate(layerName: string) {
  // select layer name for Labels
  const labelLayerName = layerName === STATIONS_ICON_LAYER_ID ? STATIONS_LABEL_LAYER : STOPS_LABEL_LAYER;
  props.map.setFilter(labelLayerName, ['any', ['==', ['get', 'id'], hoveredId.value]]);
}

/**
 * Open tooltip for stop with multiple services
 */
function displayStopSelectorOnClick(
  event: mapboxgl.MapLayerMouseEvent,
  map: mapboxgl.Map,
  tripStopTimes: Array<StopTime>,
) {
  // remove potential previous tooltip
  MapboxHelper.removeAllTooltips(map);
  if (event?.features) {
    const stopId = event.features[0]?.properties?.id;
    const duplicatedStop = tripStopTimes.filter(s => s.stop_id === stopId);
    const isStopIdDuplicate = duplicatedStop.length > 1;
    if (stopId && isStopIdDuplicate) {
      const geometry = event.features[0].geometry as GeoJSON.Point;
      const coordinates = geometry.coordinates.slice() as mapboxgl.LngLatLike;
      // Generate tooltip content
      let popupContent = ``;
      duplicatedStop.forEach((stop, index) => {
        let currentStop = '';
        if (unservedStops.value[stop.stop_sequence]) {
          currentStop = `<div id="${stop.stop_id + index}" class="multistop-tooltip">
          <i class="fas fa-redo"></i>
        <span class="multistop-tooltip__text">${
          t('restoreService') + tc('serviceCount', { count: index + 1 })
        }</span>
        </div>`;
        } else {
          currentStop = `<div id="${stop.stop_id + index}" class="multistop-tooltip">
        <i class="fas fa-times"></i>
        <span class="multistop-tooltip__text">${
          t('cancelService') + tc('serviceCount', { count: index + 1 })
        }</span>
        </div>`;
        }
        popupContent += currentStop;
      });

      new mapboxgl.Popup({
        className: 'stop-sequence-selector',
        closeButton: false,
        anchor: 'top',
      })
        .setLngLat(coordinates)
        .setHTML(popupContent)
        .addTo(map);

      // Add event listener on each stop
      nextTick(() => {
        duplicatedStop.forEach((stop, index) => {
          const stopSequence = stop.stop_sequence;
          document.getElementById(stop.stop_id + index)?.addEventListener('click', () => {
            emit('tooltipToggleStop', { event, stopSequence });
            MapboxHelper.removeAllTooltips(map);
          });
        });
      });
    }
  }
}

function createStopOrStationLayer(layerId: string, sourceId: string) {
  const layerOptions = props.options.stopsBigMarkers ? INTERPOLATE.stopsBigMarkers : INTERPOLATE.stops;
  MapboxHelper.addLayer(props.map, {
    id: layerId,
    type: 'circle',
    source: sourceId,
    filter: ['any', ['!=', 'deviation', true], ['==', 'editionHighlight', true]],
    paint: {
      'circle-radius': ['interpolate', ['linear'], ['zoom'], ...layerOptions.circleRadius],
      'circle-color': [
        'case',
        ['to-boolean', ['get', 'unserved']],
        layerOptions.circleColor[0],
        ['to-boolean', ['get', 'deviation']],
        layerOptions.circleColor[1],
        layerOptions.circleColor[2],
      ],
      'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], ...layerOptions.circleStrokeWidth],
      'circle-opacity': props.tripUpdateMode
        ? [
            'case',
            ['to-boolean', ['get', 'deviation']],
            0,
            ['to-boolean', ['get', 'editionHighlight']],
            1,
            0.5,
          ]
        : 1,
      'circle-stroke-opacity': props.tripUpdateMode
        ? [
            'case',
            ['to-boolean', ['get', 'deviation']],
            0,
            ['to-boolean', ['get', 'editionHighlight']],
            1,
            0.5,
          ]
        : 1,
      'circle-stroke-color': layerOptions.circleStrokeColor,
    },
  });
}

function createUnservedStopOrStationLayer(layerId: string, sourceId: string) {
  MapboxHelper.addLayer(props.map, {
    id: layerId,
    type: 'symbol',
    source: sourceId,
    filter: ['==', 'unserved', true],
    layout: {
      'icon-image': 'crossIcon',
      'icon-size': 0.8,
      'icon-allow-overlap': true,
    },
  });
}
function createDeviationStopOrStationLayer(layerId: string, sourceId: string) {
  MapboxHelper.addLayer(props.map, {
    id: layerId,
    type: 'symbol',
    source: sourceId,
    filter: ['==', 'deviation', true],
    layout: {
      'icon-image': 'deviationIcon',
      'icon-size': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 0.25, 15, 0.5],
      'icon-allow-overlap': true,
    },
    paint: {
      'icon-opacity': ['case', ['to-boolean', ['get', 'editionHighlight']], 1, 0.5],
    },
  });
}
</script>

<template>
  <div class="mapbox-stops"></div>
</template>
