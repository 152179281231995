<template>
  <div class="datagrid-column-selector">
    <v-menu :close-on-content-click="false" offset="8">
      <template #activator="{ props }">
        <v-btn
          height="32px"
          :class="{ 'v-btn__icon-only': showIconOnly }"
          prepend-icon="fa:fas fa-columns"
          variant="text"
          v-bind:="props"
        >
          <span v-if="!showIconOnly" class="v-btn__text">{{ $t('columns.columns') }}</span>
        </v-btn>
      </template>

      <v-list>
        <v-list-item min-height="20px">
          <Checkbox
            id="columns"
            :checked="selectedColumns.filter(v => v.selectable).length === options.length"
            :indeterminate="
              selectedColumns.length > 0 &&
              selectedColumns.filter(v => v.selectable).length !== options.length
            "
            @change="checked => changeSelectedColumns(checked ? options : [])"
          >
            <template #label>
              {{ $t('columns.allChecked') }}
            </template>
          </Checkbox>
        </v-list-item>
        <v-divider></v-divider>
        <div class="checkbox-list">
          <v-list-item v-for="column in options" :key="`${column.key}_dropdown`" min-height="20px">
            <Checkbox
              :id="column.key"
              :checked="selectedColumns.some(v => v.isEqual(column))"
              @change="
                checked =>
                  changeSelectedColumns(
                    checked ? [...selectedColumns, column] : selectedColumns.filter(v => !v.isEqual(column)),
                  )
              "
            >
              <template #label>
                {{ $t(column.title) }}
              </template>
            </Checkbox>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Checkbox from '@/components/ui/Checkbox.vue';

export default {
  name: 'DataGridColumnSelector',

  components: {
    Checkbox,
  },

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    /** @type {Vue.PropOptions<Array<import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGridColumn>>} */
    options: {
      type: Array,
      required: true,
    },
    localStorageKey: {
      type: String,
      required: true,
    },
    showIconOnly: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['onChangeColumnsSelection'],

  data() {
    return {
      dropdownOpened: false,
      /** @type {Array<import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGridColumn>} */
      selectedColumns: [],
    };
  },

  created() {
    const localStorageColumnSelection = JSON.parse(localStorage.getItem(this.localStorageKey));
    // if selected columns are saved in localStorage, apply, otherwise show all columns
    if (localStorageColumnSelection) {
      this.selectedColumns = this.options.filter(col => localStorageColumnSelection.includes(col.key));
    } else {
      // set defaultSelected if no saved filters
      this.selectedColumns = this.options.filter(col => col.defaultSelected);
    }
  },

  methods: {
    changeSelectedColumns(selection) {
      this.$emit('onChangeColumnsSelection', selection);

      // save to localStorage && update selectedColumns in component
      localStorage.setItem(this.localStorageKey, JSON.stringify(selection.map(s => s.key)));
      this.selectedColumns = selection;
    },
  },
};
</script>

<style lang="scss">
.datagrid-column-selector {
  .v-btn {
    cursor: pointer;

    &__icon-only {
      padding-inline: 5px;

      .v-btn__prepend {
        margin-inline: auto;
      }

      &.v-btn--size-default {
        min-width: 30px;
      }
    }

    .v-icon {
      font-size: 14px;
    }
  }
}

.checkbox-list {
  overflow: auto;
  max-height: 400px;
}
</style>

<i18n locale="fr">
{
  "columns": {
    "columns": "Colonnes",
    "allChecked": "Tout cocher"
  }
}
</i18n>

<i18n locale="en">
{
  "columns": {
    "columns": "Columns",
    "allChecked": "Check all"
  }
}
</i18n>

<i18n locale="cz">
{
  "columns": {
    "columns": "Sloupce",
    "allChecked": "Ověřit vše"
  }
}
</i18n>

<i18n locale="de">
{
  "columns": {
    "columns": "Spalten",
    "allChecked": "Alle auswählen"
  }
}
</i18n>

<i18n locale="es">
{
  "columns": {
    "columns": "Columnas",
    "allChecked": "Verificar todo"
  }
}
</i18n>

<i18n locale="it">
{
  "columns": {
    "columns": "Colonne",
    "allChecked": "Contrassegna tutto"
  }
}
</i18n>

<i18n locale="pl">
{
  "columns": {
    "columns": "Kolumny",
    "allChecked": "Zaznacz wszystkie"
  }
}
</i18n>
