import { defineAsyncComponent } from 'vue';
import { Feature } from '@/features.ts';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';

const EditableCell = defineAsyncComponent(() => import('@/components/Table/cells/EditableCell.vue'));
const SelectorCell = defineAsyncComponent(() => import('@/components/Table/cells/SelectorCell.vue'));

/** @enum {string} */
export const ColumnKey = {
  GROUP_ID: 'group_id',
  NAME: 'name',
  ALERTS_ENABLED: 'alertsEnabled',
  DEVIATION_ENABLED: 'deviationEnabled',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'adminGroupListDatagrid',
    hasActions: true,
    searchFields: [ColumnKey.GROUP_ID, ColumnKey.NAME],
    defaultSortBy: [{ key: ColumnKey.GROUP_ID, order: SortOrder.ASC }],
    showColumnSelector: false,
    rowIdKey: '_id',
    columns: [
      // GROUP ID
      new DataGridColumn({
        key: ColumnKey.GROUP_ID,
        title: 'column.groupId',
        cellBuilder([value, object], { placeholder, valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: value,
              editMode,
              placeholder,
              type: ColumnKey.GROUP_ID,
              value,
            },
          };
        },
      }),

      // NAME
      new DataGridColumn({
        key: ColumnKey.NAME,
        title: 'column.name',
        cellBuilder([value, object], { placeholder, valueChanged, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: object._id,
              link: `/${object._id}`,
              editMode,
              placeholder,
              type: ColumnKey.NAME,
              value,
            },
          };
        },
      }),

      // Urgent Alert
      new DataGridColumn({
        key: ColumnKey.ALERTS_ENABLED,
        title: 'column.alertsEnabled',
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: SelectorCell,
            events: { valueChanged },
            props: {
              editMode,
              i18nPrefix: 'activation',
              options: ['activate', 'deactivate'],
              value: object.features.includes(Feature.URGENCIES) ? 'activate' : 'deactivate',
              type: ColumnKey.ALERTS_ENABLED,
            },
          };
        },
      }),

      // Deviation
      new DataGridColumn({
        key: ColumnKey.DEVIATION_ENABLED,
        title: 'column.deviationEnabled',
        cellBuilder([value, object], { valueChanged, editMode }) {
          return {
            component: SelectorCell,
            events: { valueChanged },
            props: {
              editMode,
              i18nPrefix: 'activation',
              options: ['activate', 'deactivate'],
              value: object.features.includes(Feature.DEVIATION) ? 'activate' : 'deactivate',
              type: ColumnKey.DEVIATION_ENABLED,
            },
          };
        },
      }),
    ],
  });
};
