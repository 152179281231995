import { DataGrid, DataGridColumn } from '@/components/Table/DataGridVuetify/models/DataGrid.models';

/** @enum {string} */
export const ColumnKey = {
  DATE: 'dateFormatted',
  TIME_POINT_1: 'point1TsFormatted',
  TIME_POINT_2: 'point2TsFormatted',
  RECORDED_TRAVEL_TIME: 'durationFormatted',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'reportsTravelTimeDatagrid',
    showColumnSelector: false,
    hasFirstRowSticky: false,
    columns: [
      // DATE
      new DataGridColumn({
        key: ColumnKey.DATE,
        title: 'column.date',
        sortable: true,
      }),

      // TIME POINT 1
      new DataGridColumn({
        key: ColumnKey.TIME_POINT_1,
        sortable: true,
        title: 'column.timePoint1',
      }),

      // TIME POINT 2
      new DataGridColumn({
        key: ColumnKey.TIME_POINT_2,
        sortable: true,
        title: 'column.timePoint2',
      }),

      // RECORDED TRAVEL TIME
      new DataGridColumn({
        key: ColumnKey.RECORDED_TRAVEL_TIME,
        sortable: true,
        title: 'column.recordedTravelTime',
      }),
    ],
  });
};
