/** @module */
import Api from '@/api';

/** @typedef {typeof state} State */
const state = {
  /**
   * Map<UrgencyId, Urgency>
   * @type {{key:string, value: import('@/@types/api/urgency').Urgency } | Object}
   */
  list: {},
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,

  state,

  mutations: {
    resetUrgencies(state) {
      state.list = {};
    },

    updateUrgencies(state, urgencies) {
      urgencies.forEach(urgency => {
        if (!state.list[urgency.urgency_id]) {
          state.list[urgency.urgency_id] = urgency;
        } else {
          Object.assign(state.list[urgency.urgency_id], urgency);
        }
      });
    },
  },

  getters: {
    /** @returns {import('@/@types/api/urgency').Urgency[]} */
    getNewUrgencies: state => {
      return Object.values(state.list).filter(urgency => urgency.acknowledgment === undefined) || [];
    },

    /** @returns {import('@/@types/api/urgency').Urgency[]} */
    getAcknowledgedUrgencies: state => {
      const acknowledgedUrgencies = Object.values(state.list).filter(
        urgency => urgency.acknowledgment !== undefined && urgency.resolution === undefined,
      );
      acknowledgedUrgencies.sort((a, b) => {
        return b.device_time - a.device_time;
      });
      return acknowledgedUrgencies;
    },
  },

  actions: {
    async acknowledgeUrgency({ commit }, { groupId, urgencyId }) {
      const response = await Api.urgencies.acknowledge(groupId, urgencyId);
      return response;
    },

    async resolveUrgency({ commit }, { groupId, urgencyId, isFalseAlert, comment }) {
      const response = await Api.urgencies.resolve(groupId, urgencyId, isFalseAlert, comment);
      return response;
    },
  },
});

/** @enum {string} */
export const UrgencyEventType = {
  CREATE: 'urgency:create',
  ACKNOWLEDGE: 'urgency:acknowledged',
  RESOLVE: 'urgency:resolved',
};

/**
 * @typedef {Object} FormattedUrgency
 * @property {?string} driver
 * @property {?string} vehicle
 * @property {string} device
 * @property {?string} trip
 * @property {string} datetime
 * @property {string} position
 * @property {string} [address]
 */
