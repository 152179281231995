<template>
  <div class="settings">
    <div class="settings__menu">
      <Btn
        v-for="btn in [
          MenuOptions.GENERAL,
          MenuOptions.DRIVER_APP,
          MenuOptions.TEAMS,
          MenuOptions.PASSENGER_COUNT,
          MenuOptions.PUNCTUALITY,
          MenuOptions.STOP_ZONES,
          MenuOptions.DEPOT,
          MenuOptions.ROUTE_MANAGEMENT,
          MenuOptions.TRIP_MANAGEMENT,
          MenuOptions.DATA_PERIOD,
        ]"
        :key="btn"
        class="settings__buttons"
        :class="{ active: active === btn }"
        type="link-style"
        @click="onChangeTab(btn)"
        @mousedown.prevent
      >
        {{ $t(`menu.${btn}`) }}
      </Btn>
    </div>

    <div class="settings__content">
      <component
        :is="currentComponent"
        :key="currentComponent"
        @change="$changed => (changed = $changed)"
        @save="changed = false"
      />
    </div>
  </div>
</template>

<script>
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import Btn from '@/components/ui/Btn.vue';

import DataPeriod from './DataPeriod.vue';
import Driver from './Driver.vue';
import General from './General.vue';
import PassengerCount from './PassengerCount.vue';
import Punctuality from './PunctualityParams.vue';
import StopZones from './StopZones.vue';
import Teams from './Teams.vue';
import Depot from './Depot.vue';
import RouteManagement from './RouteManagement.vue';
import TripManagement from './TripManagement.vue';

/** @enum {string} */
const MenuOptions = {
  DRIVER_APP: 'driverApp',
  GENERAL: 'general',
  PASSENGER_COUNT: 'passengerCount',
  PUNCTUALITY: 'punctuality',
  STOP_ZONES: 'stopZones',
  TEAMS: 'teams',
  DEPOT: 'depots',
  ROUTE_MANAGEMENT: 'routeManagement',
  TRIP_MANAGEMENT: 'tripManagement',
  DATA_PERIOD: 'dataPeriod',
};

/** @type {{[key in MenuOptions]: import('vue').Component}} */
const componentsMap = {
  [MenuOptions.DRIVER_APP]: Driver,
  [MenuOptions.GENERAL]: General,
  [MenuOptions.PASSENGER_COUNT]: PassengerCount,
  [MenuOptions.PUNCTUALITY]: Punctuality,
  [MenuOptions.STOP_ZONES]: StopZones,
  [MenuOptions.TEAMS]: Teams,
  [MenuOptions.DEPOT]: Depot,
  [MenuOptions.ROUTE_MANAGEMENT]: RouteManagement,
  [MenuOptions.TRIP_MANAGEMENT]: TripManagement,
  [MenuOptions.DATA_PERIOD]: DataPeriod,
};

export default {
  name: 'Settings',

  components: {
    AnimatedDots,
    Btn,
  },

  beforeRouteLeave(to, from, next) {
    if (
      this.changed &&
      this.currentComponent !== componentsMap[MenuOptions.TEAMS] &&
      this.currentComponent !== componentsMap[MenuOptions.DEPOT] &&
      !window.confirm(this.$t('unsavedChanges'))
    ) {
      next(false);
    }
    next();
  },

  data: () => ({
    MenuOptions,

    /** @type {MenuOptions} */
    active: MenuOptions.GENERAL,

    /** @type {boolean} */
    changed: false,
  }),

  computed: {
    /** @return {?import('vue').Component} */
    currentComponent() {
      return componentsMap[this.active];
    },
  },

  methods: {
    /** @param {MenuOptions} tab */
    onChangeTab(tab) {
      if (
        this.changed &&
        this.currentComponent !== componentsMap[MenuOptions.TEAMS] &&
        this.currentComponent !== componentsMap[MenuOptions.DEPOT] &&
        !window.confirm(this.$t('unsavedChanges'))
      )
        return;

      this.changed = false;
      this.active = tab;
    },
  },
};
</script>

<style lang="scss">
.settings {
  display: flex;
  margin: 10px 10px 10px 0;
  padding: $view-standard-padding;

  &__content {
    flex: 1;
    margin-left: 16px;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    margin: 5px 5px 5px 0;

    :first-child {
      margin-left: 10px;
    }

    > .ui-btn {
      justify-content: left;
      color: $primary-light;
      font-family: $font-poppins;

      &.active {
        background-color: $primary-light;
        color: $text-light;
        text-decoration: none;
      }
    }
  }

  label.required::after {
    content: '*';
    margin: 0 0.5em;
  }

  .error-msg {
    margin-left: 0.5em;
    color: $danger;
  }
}
</style>

<i18n locale="fr">
{
  "menu": {
    "dataPeriod": "Période des données",
    "general": "Général",
    "driverApp": "Application conducteur",
    "teams": "Équipes",
    "passengerCount": "Comptages",
    "punctuality": "Rapport de ponctualité",
    "depots": "Gestion des dépôts",
    "stopZones": "Distances et zones d'arrêt",
    "routeManagement": "Gestion des lignes",
    "tripManagement": "Gestion des courses"
  },
  "unsavedChanges": "Il y a des changements non sauvegardés.\nVoulez-vous vraiment continuer ?"
}
</i18n>

<i18n locale="en">
{
  "menu": {
    "dataPeriod": "Data period",
    "general": "General",
    "driverApp": "Driver app",
    "teams": "Teams",
    "passengerCount": "Passenger count",
    "punctuality": "Punctuality reporting",
    "depots": "Bus depots management",
    "stopZones": "Distance and stop zones",
    "routeManagement": "Route management",
    "tripManagement": "Trip management"
  },
  "unsavedChanges": "There are unsaved changes.\nDo you really want to leave?"
}
</i18n>
