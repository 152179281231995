// Adaptated code from https://codepen.io/crwilson311/pen/Bajbdwd

import { safeGetLsAsMapObj } from './helpers/localStorage';

const RESIZE_CONTROL_CLASS = 'resize-col';
const RESIZE_CONTROL_BORDER_COLOR = '#00b871'; //$primary-light
const UNWANTED_COLUMNS_CLASSES = ['action-column', 'column-checkbox'];

/**
 * Add resize listener to an element
 */
function setListeners(div: HTMLDivElement, lsName: string) {
  let pageX: number | undefined,
    curCol: HTMLElement | undefined,
    nxtCol: HTMLElement | null | undefined,
    curColWidth: number | undefined,
    nxtColWidth: number | undefined;

  div.addEventListener('mousedown', function (e: MouseEvent) {
    const target = e?.target as HTMLDivElement;
    if (target.parentElement) {
      curCol = target.parentElement;
      nxtCol = curCol.nextElementSibling as HTMLElement;
      pageX = e.pageX;

      const padding = paddingDiff(curCol);

      curColWidth = curCol.offsetWidth - padding;
      if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
    }
  });

  div.addEventListener('mouseover', function (e: MouseEvent) {
    const target = e?.target as HTMLDivElement;
    target.style.borderRight = `1px solid ${RESIZE_CONTROL_BORDER_COLOR}`;
  });

  div.addEventListener('mouseout', function (e: MouseEvent) {
    const target = e?.target as HTMLDivElement;
    target.style.borderRight = '';
  });

  document.addEventListener('mousemove', function (e: MouseEvent) {
    if (curCol && pageX !== undefined && curColWidth !== undefined && nxtColWidth !== undefined) {
      const diffX = e.pageX - pageX;
      const nxtColCalculatedWidth = nxtColWidth - diffX;

      if (nxtCol) {
        nxtCol.style.width = `${nxtColCalculatedWidth}px`;
        saveSizeToLs(nxtCol.id, nxtColCalculatedWidth, lsName);
      }
      const curColCalculatedWidth = curColWidth + diffX;
      curCol.style.width = `${curColCalculatedWidth}px`;
      saveSizeToLs(curCol.id, curColCalculatedWidth, lsName);
    }
  });

  document.addEventListener('mouseup', function (e: MouseEvent) {
    curCol = undefined;
    nxtCol = undefined;
    pageX = undefined;
    nxtColWidth = undefined;
    curColWidth = undefined;
  });
}

/**
 * Create a div to add resize control
 */
function createDiv(height: number): HTMLDivElement {
  const div = document.createElement('div');
  div.className = RESIZE_CONTROL_CLASS;
  div.style.top = '0';
  div.style.right = '-1px';
  div.style.width = '5px';
  div.style.position = 'absolute';
  div.style.cursor = 'col-resize';
  div.style.userSelect = 'none';
  div.style.height = height + 'px';
  return div;
}

/**
 * Calculate paddingDiff for an element
 */
function paddingDiff(col: HTMLElement): number {
  if (getStyleVal(col, 'box-sizing') == 'border-box') return 0;
  const padLeft = getStyleVal(col, 'padding-left');
  const padRight = getStyleVal(col, 'padding-right');
  return parseInt(padLeft) + parseInt(padRight);
}

/**
 * Get a style value by its name
 */
function getStyleVal(elm: HTMLElement, css: string): string {
  return window.getComputedStyle(elm, null).getPropertyValue(css);
}

/** Save size in LocalStorage, update value if already exists */
function saveSizeToLs(key: string, size: number, lsName: string) {
  const savedColumnSize = safeGetLsAsMapObj(lsName);
  savedColumnSize.set(key, size);
  localStorage.setItem(lsName, JSON.stringify(Array.from(savedColumnSize.entries())));
}

export const DatagridColumnResizer = {
  /**
   * get Cols & set default size from localstorage, or reset them to default if resetSize is true
   * Returns filtered cols
   */
  setInitColsSize(
    table: HTMLTableElement,
    datagridName: string,
    resetSize: boolean = false,
  ): HTMLElement[] | undefined {
    const columnSizeLocalStorage = `${datagridName}/columnSize`;

    // get cols elements
    const row = table.querySelector('tr');
    const cols = row ? (row.children as HTMLCollectionOf<HTMLElement>) : undefined;
    if (!cols) return undefined;

    // We dont want controls to specific columns
    const filteredCols = Array.from(cols).filter(
      col => !UNWANTED_COLUMNS_CLASSES.some(className => col.className.includes(className)),
    );
    // neither we don't want the last column
    filteredCols.pop();

    // get previously saved column size
    const savedColumnSize = safeGetLsAsMapObj(columnSizeLocalStorage);

    // for every cols, create a div and add a listener to it
    for (const col of filteredCols) {
      if (resetSize) {
        // reset previously set width if resetSize is true (case when user add optional columns to the table)
        localStorage.removeItem(columnSizeLocalStorage);
        col.style.width = '';
      } else {
        // Set previously saved size if exists
        const previouslySavedSize = savedColumnSize.get(col.id);
        if (previouslySavedSize) col.style.width = `${previouslySavedSize}px`;
      }
      if (col !== filteredCols[0]) col.style.position = 'relative';
    }
    return filteredCols;
  },

  /**
   * Complete process to get columns from a table & add size controler
   * Also call setInitColsSize to set default size from localstorage
   */
  addResizeControls(table: HTMLTableElement, datagridName: string, resetSize: boolean = false) {
    const columnSizeLocalStorage = `${datagridName}/columnSize`;
    const tableHeight = table.offsetHeight;

    // Remove potential previously created controls
    const previousResizeCols = Array.prototype.slice.call(
      document.getElementsByClassName(RESIZE_CONTROL_CLASS),
    );
    if (previousResizeCols?.length > 0) {
      for (const element of previousResizeCols) {
        element.remove();
      }
    }

    const filteredCols = this.setInitColsSize(table, datagridName, resetSize);
    if (!filteredCols) return;
    // for every cols, create a div and add a listener to it
    for (const col of filteredCols) {
      const div = createDiv(tableHeight);
      col.appendChild(div);
      if (col !== filteredCols[0]) col.style.position = 'relative';
      setListeners(div, columnSizeLocalStorage);
    }
  },
};
