import { defineAsyncComponent } from 'vue';
import { DataGrid, DataGridColumn } from '@/components/Table/DataGridVuetify/models/DataGrid.models';

const ClickableCell = defineAsyncComponent(() => import('@/components/Table/cells/ClickableCell.vue'));

/** @enum {string} */
export const ColumnKey = {
  VERSION: 'version',
  TOTAL: 'total',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'adminVersionListDatagrid',
    hasActions: false,
    searchFields: [ColumnKey.VERSION, ColumnKey.TOTAL],
    showColumnSelector: false,
    rowIdKey: 'version',
    columns: [
      new DataGridColumn({
        key: ColumnKey.VERSION,
        title: 'column.appVersion',
        filterable: true,
        cellBuilder([value, object], { click }) {
          return {
            component: ClickableCell,
            events: { click },
            props: {
              highlight: true,
              displayValue: object.version,
              version: object.version,
            },
            value: object.version,
          };
        },
      }),
      new DataGridColumn({
        key: ColumnKey.TOTAL,
        title: 'Total',
        sortable: true,
      }),
    ],
  });
};
