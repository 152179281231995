<template>
  <div class="datagrid__filter-eraser">
    <v-menu v-model="menu" offset="8" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn
          height="32px"
          prepend-icon="fa:fas fa-filter"
          :variant="!hasActiveFilters ? 'text' : 'tonal'"
          v-bind:="props"
          :color="!hasActiveFilters ? 'default' : 'success'"
          :disabled="!hasActiveFilters || disabled"
          :class="{ 'datagrid__filter-eraser__icon-only': showIconOnly }"
        >
          <span v-if="!showIconOnly" class="v-btn__text">{{ buttonText }}</span>
        </v-btn>
      </template>

      <v-card class="mx-auto" width="320">
        <v-toolbar flat>
          <v-btn icon @click="$emit('eraseFilters')">
            <v-icon>fa:fas fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('clearAll') }}</v-toolbar-title>
        </v-toolbar>
        <div class="datagrid__filter-eraser__chip-list">
          <template v-for="(filter, index) in filters" :key="index">
            <v-chip
              v-if="filter.isActive"
              label
              close-icon="fa:fas fa-times"
              closable
              color="#4f4f4f"
              @click:close="$emit('eraseFilters', filter)"
            >
              {{ $t(filter.columnName) }}
            </v-chip>
          </template>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'DataGridFilterEraser',

  props: {
    lsFiltersName: {
      type: String,
      required: true,
    },
    /** @type {import('vue').Prop<import('@/components/Table/DataGridVuetify/models/DataGrid.models').ColumnFilterState>} */
    filters: {
      required: true,
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showIconOnly: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['eraseFilters'],

  data() {
    return { hasActiveFilters: false, activeFilterCounter: 0, menu: false };
  },
  computed: {
    buttonText() {
      let text = this.$t('filters');
      if (this.activeFilterCounter > 0) text += ` (${this.activeFilterCounter})`;
      return text;
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler() {
        if (Object.keys(this.filters).length > 0) {
          const activeFilters = Object.keys(this.filters).filter(key => this.filters[key].isActive);
          this.hasActiveFilters = activeFilters.length > 0;
          this.activeFilterCounter = activeFilters.length;
        }
      },
    },
    hasActiveFilters() {
      if (!this.hasActiveFilters) this.menu = false;
    },
  },
};
</script>

<style lang="scss">
.datagrid__filter-eraser {
  .v-icon--size-default {
    font-size: 14px;
  }

  .v-btn {
    width: 105px;
    cursor: pointer;

    .v-btn__underlay:not(:hover) {
      opacity: 0;
    }

    &:hover {
      .v-btn__overlay {
        background: $primary-light;
        opacity: 0.1;
      }
    }

    &[aria-expanded='true'] {
      .v-btn__overlay {
        opacity: 0.25;
      }
    }

    &.datagrid__filter-eraser__icon-only {
      width: 30px;

      .v-btn__prepend {
        margin-inline: auto;
      }

      &.v-btn--size-default {
        min-width: 25px;
      }
    }
  }

  &__chip-list {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 6px;
    margin: 12px;
  }
}
</style>

<i18n locale="fr">
  {
    "filters": "Filtres",
    "clearAll": "Tout effacer"
  }
  </i18n>

<i18n locale="en">
  {
    "filters": "Filters",
    "clearAll": "Clear all"
  }
  </i18n>
