<script setup lang="ts">
import mapboxgl from 'mapbox-gl';
import { computed, onMounted, onUnmounted, type PropType, ref, watch } from 'vue';
import {
  DEPOTS_ICON_LAYER_ID,
  DEPOTS_LABEL_LAYER_ID,
  DEPOTS_SOURCE_ID,
  DEPOTS_ZONE_LAYER_ID,
  DEPOTS_ZONE_SOURCE_ID,
} from '@/@types/mapbox';
import { MapboxHelper } from './mapboxHelper';
import { createGeoJSONCircle } from '@/libs/helpers/geo';
import type { MapDepot } from '@/store-pinia/depots';

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },
  depots: {
    type: Array as PropType<Array<MapDepot>>,
    required: true,
  },
  editing: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['isLoaded']);

const hoveredId = ref<string | null>(null);

const depotSource = computed<Array<GeoJSON.Feature<GeoJSON.Point>>>(() => {
  return props.depots.map(depot => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [depot.longitude || 0, depot.latitude || 0],
    },
    properties: {
      id: depot.id,
      label: depot.name,
    },
  }));
});

const depotZoneSource = computed<Array<GeoJSON.Feature<GeoJSON.Polygon>>>(() => {
  return props.depots.map(depot => ({
    type: 'Feature',
    geometry: createGeoJSONCircle([depot.longitude || 0, depot.latitude || 0], depot.radius),
    properties: {
      id: depot.id,
    },
  }));
});

watch(
  () => depotSource,
  () => {
    MapboxHelper.updateSource(props.map, DEPOTS_SOURCE_ID, depotSource.value);
  },
  { deep: true, immediate: true },
);

watch(
  () => depotZoneSource,
  () => {
    MapboxHelper.updateSource(props.map, DEPOTS_ZONE_SOURCE_ID, depotZoneSource.value);
  },
  { deep: true, immediate: true },
);

watch(
  () => props.editing,
  editing => {
    if (editing) {
      props.map.getCanvas().style.cursor = 'crosshair';
    } else {
      props.map.getCanvas().style.cursor = '';
    }
  },
);

onMounted(() => {
  initSourceAndLayer();
  initMapActions();
  emit('isLoaded', true);
});

onUnmounted(() => {
  MapboxHelper.cleanLayersAndSources(
    props.map,
    [DEPOTS_ZONE_LAYER_ID, DEPOTS_ICON_LAYER_ID, DEPOTS_LABEL_LAYER_ID],
    [DEPOTS_SOURCE_ID, DEPOTS_ZONE_SOURCE_ID],
  );
});

function initSourceAndLayer() {
  MapboxHelper.createEmptySource(props.map, DEPOTS_SOURCE_ID);
  MapboxHelper.createEmptySource(props.map, DEPOTS_ZONE_SOURCE_ID);

  MapboxHelper.addLayer(props.map, {
    id: DEPOTS_ZONE_LAYER_ID,
    type: 'fill',
    source: DEPOTS_ZONE_SOURCE_ID,
    paint: {
      'fill-color': '#ff0000',
      'fill-opacity': 0.35,
    },
  });

  MapboxHelper.addLayer(props.map, {
    id: DEPOTS_ICON_LAYER_ID,
    type: 'circle',
    source: DEPOTS_SOURCE_ID,
    paint: {
      'circle-radius': 3,
      'circle-color': '#f5f5f5', // $background
      'circle-stroke-width': 1,
      'circle-stroke-color': '#00894e', // $primary-dark
    },
  });

  MapboxHelper.addLayer(props.map, {
    id: DEPOTS_LABEL_LAYER_ID,
    type: 'symbol',
    source: DEPOTS_SOURCE_ID,
    filter: ['any', ['==', ['get', 'id'], hoveredId.value]],

    layout: {
      'text-allow-overlap': true,
      'text-anchor': 'top-left',
      'text-field': ['get', 'label'],
      'text-justify': 'left',
      'text-offset': [0.8, -0.4],
    },
    paint: {
      'text-halo-color': '#ffffff',
      'text-halo-width': 3,
    },
  });
}

function initMapActions() {
  props.map.on('mouseleave', DEPOTS_ICON_LAYER_ID, e => {
    onStopMouseLeave(e, DEPOTS_LABEL_LAYER_ID);
  });
  props.map.on('mousemove', DEPOTS_ICON_LAYER_ID, e => {
    onStopMouseMove(e, DEPOTS_LABEL_LAYER_ID);
  });
}

function onStopMouseLeave(event: mapboxgl.MapLayerMouseEvent, layerName: string) {
  hoveredId.value = null;
  props.map.setFilter(layerName, ['any', ['==', ['get', 'id'], hoveredId.value]]);
}

function onStopMouseMove(event: mapboxgl.MapLayerMouseEvent, layerName: string) {
  if (event?.features && event.features.length > 0) {
    const depotId = event.features[0]?.properties?.id;
    if (hoveredId.value !== depotId) {
      hoveredId.value = depotId;
    }
    props.map.setFilter(layerName, ['any', ['==', ['get', 'id'], hoveredId.value]]);
  }
}
</script>

<template>
  <div class="mapbox-depots"></div>
</template>
