<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import { dateGtfsFormatToObj } from '@/libs/helpers/dates';

import { ColumnKey, getDatagrid } from './ArchivedUrgenciesList.conf';

const props = defineProps({
  /** @type {import('vue').Prop<Array<import('@/@types/api/urgency').Urgency>>} */
  archivedUrgencies: {
    type: Array,
    required: true,
  },
  showFullHeight: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const { t } = useI18n();

/** @type {import('vue').Ref<Array<UrgencyFormatted>>} */
const archivedUrgenciesFormatted = ref([]);
/** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
const datagrid = getDatagrid();
/** @type {import('vue').Ref<{[tripId: string]: import('@/store/gtfs').Trip}>} */
const gtfsTrips = ref({});
/** @type {import('vue').Ref<boolean>} */
const loading = ref(true);
/** @type {import('vue').Ref<number>} */
const renderedDataLength = ref(0);
/** @type {import('vue').Ref<{[routeId: string]: import('@/store/gtfs').Route}>} */
const routeList = ref({});

/** @return {{[key in ColumnTypes]: (data: {apiData: StopListItem) => Object}} */
const buildCellInjectors = computed(() => {
  return {
    [ColumnKey.TRIP_FORMATTED_NAME]: () => ({ groupId: store.getters.group._id }),
  };
});

/** @return {Array<import('@/store/drivers').Driver>} */
const driverList = computed(() => {
  return Object.values(store.state.drivers.list);
});

/**
 * @param {string} tripId
 * @return {Promise<string>}
 */
async function generateFormattedTripName(tripId, date) {
  return store.dispatch('gtfs/formatTripName', {
    tripId,
    date,
  });
}

async function formatArchivedUrgencies() {
  loading.value = true;
  // Remove non-resolved urgencies
  const filteredUrgencies = props.archivedUrgencies.filter(urgency => urgency.resolution);
  archivedUrgenciesFormatted.value = await Promise.all(
    filteredUrgencies.map(async urgency => {
      const tripFormattedName = urgency.trip
        ? await generateFormattedTripName(urgency.trip.id, dateGtfsFormatToObj(urgency.trip.start_date))
        : undefined;
      const routeId = urgency.trip ? gtfsTrips.value[urgency.trip.id]?.route_id : undefined;
      return {
        id: urgency.id,
        sendTime: new Date(urgency.device_time),
        receptionTime: new Date(urgency.api_time * 1000),
        acknowledgmentTime: urgency.acknowledgment ? new Date(urgency.acknowledgment.time) : null,
        acknowledgmentOperator: urgency.acknowledgment?.user_id,
        driver: driverList.value.find(driver => driver.id === urgency.driver_id)?.driver_name,
        device: urgency.device_id,
        trip: urgency.trip,
        resolutionTime: urgency.resolution ? new Date(urgency.resolution.time) : null,
        resolutionOperator: urgency.resolution?.user_id,
        tripFormattedName,
        route: routeId ? routeList.value[routeId] : undefined,
        routeShortName: routeId ? routeList.value[routeId]?.route_short_name : undefined,
        isFalseAlert: urgency.resolution?.is_false_alert ? t('Yes') : t('No'),
        comment: urgency.resolution?.comment,
      };
    }),
  );
  loading.value = false;
}

async function loadRoutes() {
  routeList.value = await store.dispatch('gtfs/getRoutesMap');
}

async function loadTrips() {
  gtfsTrips.value = await store.dispatch('gtfs/getTripsMap');
}

watch(
  () => props.archivedUrgencies,
  () => formatArchivedUrgencies(),
);

onMounted(() => {
  loadRoutes();
  loadTrips();
});

/**
 * @typedef {Object} UrgencyFormatted
 * @property {string} id
 * @property {?Date} sendTime
 * @property {Date} receptionTime
 * @property {?Date} acknowledgmentTime
 * @property {string} acknowledgmentOperator
 * @property {?string} driver
 * @property {string} device
 * @property {?UrgencyTrip} trip
 * @property {?Date} resolutionTime
 * @property {string} resolutionOperator
 * @property {?import('@/store/gtfs').Route} route
 * @property {?string} routeShortName
 * @property {string} isFalseAlert
 * @property {?string} comment
 */

/**
 * @typedef {Object} UrgencyTrip
 * @property {string} gtfs_id
 * @property {string} id
 * @property {string} start_date
 */
</script>

<template>
  <DataGridVuetify
    ref="dataGrid"
    v-model:rendered-data-length="renderedDataLength"
    class="archived-urgencies-table"
    :class="{ 'full-height': props.showFullHeight }"
    :title="$t('archivedUrgencies', { count: renderedDataLength })"
    :data="archivedUrgenciesFormatted"
    :datagrid="datagrid"
    :loading="loading"
    :show-header="false"
    :build-cell-injectors="buildCellInjectors"
  />
</template>

<style lang="scss">
.archived-urgencies-table {
  $urgencies-datagrid-height: calc(100vh - ($view-standard-padding * 2) - $navbar-top - 54px - 52px - 280px);
  $urgencies-datagrid-full-height: calc(100vh - ($view-standard-padding * 2) - $navbar-top - 56px);

  &.datagrid-vuetify {
    .v-table__wrapper {
      height: $urgencies-datagrid-height;
    }
  }

  &.full-height {
    &.datagrid-vuetify {
      .v-table__wrapper {
        height: $urgencies-datagrid-full-height;
      }
    }
  }
}
</style>

<i18n locale="fr">
  {
    "archivedUrgencies": "Urgence archivée | Urgences archivées",
  }
</i18n>

<i18n locale="en">
  {
    "archivedUrgencies": "Archived Urgency | Archived Urgencies",
  }
</i18n>
