<template>
  <div class="live-map-device-list">
    <div class="live-map-device-list__sort-and-search">
      <div class="live-map-device-list__sort">
        <SortFilterButtons
          v-model:sorting="sortFilter"
          :fields="sortFields"
          :ls-storage-key="StorageKey.SORT_DEVICES"
        />
      </div>
      <div class="live-map-device-list__search">
        <SearchBar
          ref="searchDevice"
          :search-fields="['driver', 'vehicle', 'formattedTripName']"
          :search-list="deviceListFormated"
          @filteredList="setSearchedList"
        />
      </div>
    </div>

    <NoDeviceConnected v-if="sortedDevices.length === 0" class="live-map-device-list__no-device" />

    <div v-else class="live-map-device-list__cards card-devices">
      <div id="devices" @click.self="deselectTrip">
        <div
          v-for="deviceId in sortedDevices"
          :id="'device_' + deviceId"
          :key="deviceId"
          class="card-devices__row"
          :class="liveMapDeviceRowClass(deviceId)"
          @click="selectATrip(deviceId)"
        >
          <LiveMapDeviceCard
            :device-id="deviceId"
            @change:formattedTripName="$event => onDeviceTripChange(deviceId, $event)"
            @formatedDeviceItem="updateDeviceListFormated"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LiveMapDeviceCard from './LiveMapDeviceCard.vue';
import NoDeviceConnected from './NoDeviceConnected.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import SortFilterButtons, { SortDirection } from '@/components/common/SortFilterButtons.vue';

export const SortField = {
  ROUTE: 'route',
  DELAY: 'delay',
  PASSENGER_COUNT: 'passengerCount',
};

/** @enum {string} */
const StorageKey = {
  SORT_DEVICES: 'settings.op.livemap.sortDevice',
};

export default {
  name: 'LiveMapDeviceList',

  components: {
    LiveMapDeviceCard,
    NoDeviceConnected,
    SearchBar,
    SortFilterButtons,
  },

  props: {
    selectedDeviceId: {
      type: String,
      required: false,
      default: null,
    },
    sortedDevices: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'change:formattedTripName',
    'update:selectedDeviceId',
    'searchFilterList',
    'updateSortFilter',
    'deviceListFormated',
  ],
  data() {
    return {
      StorageKey,
      deviceListFormated: [],
      /** @type {?Array<import('@/components/common/SortFilterButtons.vue').FieldOptions>} */
      sortFields: [
        { id: SortField.ROUTE, label: this.$t('route') },
        { id: SortField.DELAY, label: this.$t('delay') },
        { id: SortField.PASSENGER_COUNT, label: this.$t('passengerCount') },
      ],
      /** @type {import('@/components/common/SortFilterButtons.vue').SortFilter} */
      sortFilter: {
        direction: SortDirection.ASC,
        field: { id: SortField.ROUTE, label: this.$t('route') },
      },
    };
  },

  watch: {
    selectedDeviceId() {
      this.$nextTick(() => {
        if (!this.selectedDeviceId) return;
        const element = document.getElementById(`device_${this.selectedDeviceId}`);
        if (!element) return;
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      });
    },
    sortFilter: {
      deep: true,
      handler() {
        this.$emit('updateSortFilter', this.sortFilter);
      },
    },
  },
  methods: {
    liveMapDeviceRowClass(deviceId) {
      if (this.isSelectedTrip(deviceId) && this.selectedDeviceId) {
        return 'card-devices__row--selected';
      }
      if (!this.isSelectedTrip(deviceId) && this.selectedDeviceId) {
        return 'card-devices__row--not-selected';
      }
      return '';
    },
    isSelectedTrip(deviceId) {
      return deviceId === this.selectedDeviceId;
    },
    selectATrip(deviceId) {
      if (this.selectedDeviceId === deviceId) {
        this.$emit('update:selectedDeviceId', null);
      } else {
        this.$emit('update:selectedDeviceId', deviceId);
      }
    },
    deselectTrip() {
      this.$emit('update:selectedDeviceId', null);
    },
    /**
     * @param {string} formattedTripName
     */
    onDeviceTripChange(formattedTripName) {
      this.$emit('change:formattedTripName', formattedTripName);
    },
    updateDeviceListFormated(device) {
      const index = this.deviceListFormated.findIndex(d => d._id === device._id);
      if (index === -1) this.deviceListFormated.push(device);
      else this.deviceListFormated[index] = device;
      this.$emit('deviceListFormated', this.deviceListFormated);
    },
    setSearchedList(list) {
      const deviceIdList = [];
      list?.forEach(result => {
        deviceIdList.push(result._id);
      });
      this.$emit('searchFilterList', deviceIdList);
    },
  },
};
</script>

<style lang="scss">
.live-map-device-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .live-map-device-card:first-child {
    margin-top: 0 !important;
  }

  &__sort-and-search {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: $view-standard-padding;
    border-bottom: 1px solid $light-border;

    @media (max-width: 1200px) {
      border-top: 1px solid $border;
    }
  }

  &__search {
    width: 100%;

    .table-search-bar {
      margin: 0;

      &__input {
        height: 36px;
      }
    }
  }

  &__cards {
    flex: 1;
    overflow: auto;
    padding: $view-standard-padding $view-standard-padding 0;

    /* Hide scrollbar on every bowsers */
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .card-devices {
    overflow: auto;

    &__header {
      display: flex;
      padding: 1em;
      border-bottom: 1px solid $border-variant;
      color: $text-neutral;
      font-weight: $font-weight-semi-bold;

      &--grow {
        flex-grow: 1;
      }
    }

    &__row {
      &--not-selected {
        opacity: 0.3;
      }

      &--selected {
        .live-map-device-card {
          border-color: $text-dark;
          box-shadow: 0 0 6px rgb(0 0 0 / 15%);
        }
      }
    }
  }

  &__no-device {
    margin: $view-standard-padding;

    @media (max-width: 500px) {
      font-size: 12px;
    }

    @media (max-width: 400px) {
      background-image: none !important;
    }
  }
}
</style>

<i18n locale="fr">
  {
    "passengerCount": "Comptage voyageurs",
    "route": "Ligne",
    "delay": "Retard",
  }
</i18n>

<i18n locale="en">
  {
    "passengerCount": "Passenger count",
    "route": "Route",
    "delay": "Delay",

  }
</i18n>
