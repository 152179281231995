import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import toObject from 'dayjs/plugin/toObject';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { padLeft } from './strings';

dayjs.extend(duration);
dayjs.extend(toObject);
dayjs.extend(customParseFormat);

export enum DatetimeFormat {
  HHMM = 'HH:MM',
  HHMMSS = 'HH:MM:SS',
  DDMMYYYY = 'DD/MM/YYYY',
  GTFS_DATE = 'gtfsDate', // YYYYMMDD
  GTFS_DATE_QUERY = 'gtfsDateQuery', // YYYY-MM-DD
}
export interface FormatAndUnixOptions {
  format: DatetimeFormat;
  unix?: boolean;
  tz?: string;
}
export interface RefDateTzOptions {
  refDate?: Date;
  tz?: string;
}
export interface TimeObject {
  hours: number;
  minutes: number;
  seconds: number;
}

/**
 * Convert a date String formatted like YYYYMMDD (as in GTFS specification) to a Date object.
 */
function dateGtfsFormatToObj(gtfsDate: string): Date | null {
  try {
    const year = gtfsDate.slice(0, 4);
    const month = gtfsDate.slice(4, 6);
    const day = gtfsDate.slice(6, 8);
    return new Date(Number.parseInt(year), Number.parseInt(month) - 1, Number.parseInt(day));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
}

/**
 * Converts a date to Midnight date as specified by GTFS (Noon minus 12 hours).
 *
 * @see https://gist.github.com/derhuerst/574edc94981a21ef0ce90713f1cff7f6
 */
function dateMidnight(date: string | number | Date, tz?: string): Date {
  const noon = dateNoon(date);
  const midnight = new Date(noon.getTime() - 3600 * 12 * 1000);
  midnight.setHours(midnight.getHours(), 0, 0, 0);

  if (!tz) return midnight;
  return new Date(midnight.getTime() - getTzDelta(midnight, tz) * 1000);
}

function dateNoon(date: string | number | Date, tz?: string): Date {
  const noon = new Date(date);
  noon.setHours(12, 0, 0, 0);
  if (!tz) return noon;
  return new Date(noon.getTime() - getTzDelta(noon, tz) * 1000);
}

/**
 * Convert a Date object to String formatted like YYYYMMDD (as in GTFS specification).
 */
function dateObjToGtfsFormat(date: Date): string {
  return dayjs(date).format('YYYYMMDD');
}

/**
 * Convert a timestamp to String formatted like YYYYMMDD or YYYYY-MM-DD
 */
function timestampToGtfsFormat(ts: number, noHyphen?: boolean): string {
  if (noHyphen) return dateObjToGtfsFormat(new Date(ts));
  return dayjs(new Date(ts)).format('YYYY-MM-DD');
}

/**
 * Convert a timestamp in locale date string accorded to format option.
 */
function dateToFormattedString(value: number, options: FormatAndUnixOptions): string | null {
  if (!value || !options || !options.format) return null;

  const initialDate = options.unix && typeof value === 'number' ? value * 1000 : value;
  const tzDelta = getTzDelta(initialDate, options.tz);
  const date = new Date(initialDate + tzDelta * 1000);

  switch (options.format) {
    case DatetimeFormat.HHMM:
      return new Date(date).toLocaleString().substr(11, 5);
    case DatetimeFormat.HHMMSS:
      return new Date(date).toLocaleString().substr(11, 8);
    case DatetimeFormat.DDMMYYYY:
      return new Date(date).toLocaleString().substr(0, 10);
    case DatetimeFormat.GTFS_DATE:
      return dateObjToGtfsFormat(new Date(date));
    case DatetimeFormat.GTFS_DATE_QUERY: {
      return dayjs(new Date(date)).format('YYYY-MM-DD');
    }
    default: // format not supported
      throw new Error(`Unknown format ${options.format} for dateToFormattedString()`);
  }
}

/**
 * Format a delay to string.
 */
function formatDelay(delay: number): string {
  // TODO: [I18n] Doesn't support localization.
  return [delay < 0 ? '-' : '', Math.floor(Math.abs(delay) / 60), 'min ', Math.abs(delay) % 60, 's'].join('');
}

/**
 * Convert seconds to fromat 'HH:mm:ss'
 */
function formatSecondsToHHMMSS(sec: number): string {
  if (!sec) return '-';
  return dayjs.duration(sec, 'seconds').format('HH:mm:ss');
}

/**
 * Return date part of ISO 8601 format from `date`.
 */
function getISODate(date: Date): string {
  return dayjs(date).format('YYYY-MM-DD');
}

/**
 * Compute timezone offset in seconds between user's browser locale and given timezone.
 */
function getTzDelta(date: string | number | Date, tz?: string): number {
  if (!tz) return 0;

  const referenceDate = new Date(date);

  const reference = {
    hours: referenceDate.getHours(),
    minutes: referenceDate.getMinutes(),
    seconds: referenceDate.getSeconds(),
  };

  const dateString = referenceDate.toLocaleDateString('en');
  const localeDateString = referenceDate.toLocaleDateString('en', {
    timeZone: tz,
    hour12: false,
    hourCycle: 'h24',
  });

  const daysOffset = (new Date(localeDateString).getTime() - new Date(dateString).getTime()) / 1000;

  const localeTimeString = referenceDate.toLocaleTimeString('en', {
    timeZone: tz,
    hour12: false,
    hourCycle: 'h24',
  });

  const parsed = timeHHmmToTimeObj(localeTimeString);

  const tzDelta =
    daysOffset +
    (parsed.hours - reference.hours) * 3600 +
    (parsed.minutes - reference.minutes) * 60 +
    (parsed.seconds - reference.seconds);

  return tzDelta;
}

/**
 * For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 * @return {[number, number]} year and week number.
 */
function getWeekNumber(date: Date): [number, number] {
  // Copy date so don't modify original
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);

  return [d.getUTCFullYear(), weekNo];
}

/**
 * Take a startDate and an endDate, return endDate corresponding to 31days period
 */
function recalculateMaxDateTo31days(startDate: Date, endDate: Date): Date {
  const copyDateStart = dayjs(startDate);
  let copyDateEnd = endDate;

  const startDatePlusOneMonth = copyDateStart.add(30, 'day').toDate();
  // if more than 1 month selected, endDate become startDate + 1 month
  if (startDatePlusOneMonth < endDate) {
    copyDateEnd = startDatePlusOneMonth;
  }
  return copyDateEnd;
}

/**
 * Convert seconds in a relative time format. When value represent more than a
 * day, returns `false`.
 */
function secondsFormatTime(seconds: number): string | false {
  // TODO: [I18n] Doesn't support localization.
  const value = Math.floor(seconds);
  if (value < 60) {
    // just seconds.
    return `${value}s`;
  }
  if (value < 3600) {
    // Minutes & seconds.
    const minutes = Math.floor(value / 60);
    const seconds = value - minutes * 60;
    return `${minutes}mn${seconds}s`;
  }
  if (value < 86400) {
    // Hours, minutes & secondes.
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - hours * 3600) / 60);
    const seconds = value - hours * 3600 - minutes * 60;
    return `${hours}h${minutes}mn${seconds}s`;
  }
  // More than a day.
  return false;
}

function secondsToStr(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const pad = (v: number) => String(v).padStart(2, '0');

  return `${pad(hours)}:${pad(minutes)}`;
}

function strToSeconds(time: string): number {
  const parts = time.split(':');

  return Number(parts[0]) * 3600 + Number(parts[1]) * 60;
}

/**
 * Convert a timestamp in locale date format.
 * timestampFormatHHMM - HH:MM time format
 */
function timestampFormatDate(timestamp: number, tz?: string): string {
  const dateTimestamp = timestamp * 1000;
  const tzDelta = getTzDelta(dateTimestamp, tz);

  const date = new Date(dateTimestamp + tzDelta * 1000);
  return date.toLocaleDateString();
}

/**
 * Convert a timestamp in locale date format and HH:MM time format.
 * timestampFormatHHMM - HH:MM time format.
 */
function timestampFormatDateAndHHMM(timestamp: number, tz?: string): string {
  const formatedDate = timestampFormatDate(timestamp, tz);
  const formatedTimestamp = timestampFormatHHMM(timestamp, { tz: tz });
  return `${formatedDate} - ${formatedTimestamp}`;
}

/**
 * Convert a timestamp in HH:MM time format.
 * Options : reference date to show day delta, tz to apply tzDelta
 */
function timestampFormatHHMM(timestamp: number, options?: RefDateTzOptions): string {
  const dateTimestamp = timestamp * 1000;
  const tzDelta = getTzDelta(dateTimestamp, options?.tz);

  const date = new Date(dateTimestamp + tzDelta * 1000);

  let res = padLeft(date.getHours(), 2, '0');
  res += ':';
  res += padLeft(date.getMinutes(), 2, '0');

  const refDate = options?.refDate;
  if (refDate) {
    date.setHours(refDate.getHours());
    const dDay = Math.round((date.valueOf() - refDate.valueOf()) / 864e5);
    if (dDay > 0) res += ` (J+${dDay})`;
    if (dDay < 0) res += ` (J${dDay})`;
  }

  return res;
}

/**
 * Convert a timestamp in HH:MM:SS time format.
 */
function timestampFormatHHMMSS(timestamp: number, options?: RefDateTzOptions): string {
  const dateTimestamp = timestamp * 1000;
  const tzDelta = getTzDelta(dateTimestamp, options?.tz);

  const date = new Date(dateTimestamp + tzDelta * 1000);

  let res = padLeft(date.getHours(), 2, '0');
  res += ':';
  res += padLeft(date.getMinutes(), 2, '0');
  res += ':';
  res += padLeft(date.getSeconds(), 2, '0');

  const refDate = options?.refDate;
  if (refDate) {
    date.setHours(refDate.getHours());
    const dDay = Math.round((date.valueOf() - refDate.valueOf()) / 864e5);
    if (dDay > 0) res += ` (J+${dDay})`;
    if (dDay < 0) res += ` (J${dDay})`;
  }

  return res;
}

/**
 * Take an HH:mm time and return a TimeObject
 * Return null if invalid input
 */
function timeHHmmToTimeObj(hhmmTime: string, disableSeconds: boolean = false): TimeObject {
  const [hours, minutes, seconds] = hhmmTime.split(':');
  return {
    hours: hours !== undefined ? parseInt(hours) : 0,
    minutes: minutes !== undefined ? parseInt(minutes) : 0,
    seconds: seconds !== undefined && !disableSeconds ? parseInt(seconds) : 0,
  };
}

/**
 * Take a timestamp and return a TimeObject
 */
function timestampToTimeObj(timestamp: number, options?: RefDateTzOptions): TimeObject {
  const tzDelta = getTzDelta(timestamp, options?.tz);

  const date = new Date(timestamp + tzDelta * 1000);

  const dayjsDateObj = dayjs(date).toObject();
  return {
    hours: dayjsDateObj.hours,
    minutes: dayjsDateObj.minutes,
    seconds: dayjsDateObj.seconds,
  };
}

/**
 * Take a timeObject and a current Date on format YYYYMMDD and return a timestamp
 */
function timeObjToTimestamp(currentDate: string, timeObject: TimeObject): number {
  let date = dayjs(currentDate, 'YYYYMMDD');
  date = date.hour(timeObject.hours).minute(timeObject.minutes).second(timeObject.seconds);
  return date.unix();
}

/**
 * Converts a date to Midnight timestamp in seconds as specified by GTFS (Noon minus 12 hours).
 *
 * @see https://gist.github.com/derhuerst/574edc94981a21ef0ce90713f1cff7f6
 */
function timestampMidnight(date: string | number | Date, tz?: string): number {
  return dateMidnight(date, tz).getTime() / 1000;
}

export {
  dateGtfsFormatToObj,
  dateMidnight,
  dateNoon,
  dateObjToGtfsFormat,
  timestampToGtfsFormat,
  dateToFormattedString,
  formatDelay,
  formatSecondsToHHMMSS,
  getISODate,
  getTzDelta,
  getWeekNumber,
  recalculateMaxDateTo31days,
  secondsFormatTime,
  secondsToStr,
  strToSeconds,
  timestampFormatDate,
  timestampFormatDateAndHHMM,
  timestampFormatHHMM,
  timestampFormatHHMMSS,
  timeHHmmToTimeObj,
  timeObjToTimestamp,
  timestampMidnight,
  timestampToTimeObj,
};
