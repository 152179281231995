<template>
  <div class="travel-time-map">
    <DropdownCheckbox v-model="routesOptions" class="travel-time-map__dropdown" />

    <MapboxMap
      v-model:bounds="mapBounds"
      :gtfs-id="gtfsId"
      :stops="mapStops"
      :trips="mapTrips"
      :stops-options="{
        stopsZones: false,
      }"
      border-radius="8px"
      @load="onMapLoad"
      @click:stop="setPoint($event, true)"
      @click:trip="setPoint($event)"
      @mouseenter:stop="onMapPointEnter"
      @mouseenter:trip="onMapPointEnter"
      @mouseleave:stop="onMapPointLeave"
      @mouseleave:trip="onMapPointLeave"
    >
      <MapboxTravelTimeLayer
        v-if="mapLoaded"
        :radius-source="radiusSource"
        :map="map"
        :points-source="pointsSource"
      />
    </MapboxMap>
  </div>
</template>

<script>
import MapboxMap from '@/components/map/MapboxMap.vue';
import MapboxTravelTimeLayer from '@/components/map/MapboxTravelTimeLayer.vue';
import { HighlightType } from '@/@types/mapbox';
import DropdownCheckbox from '@/components/ui/DropdownCheckbox.vue';
import { createGeoJSONCircle } from '@/libs/helpers/geo';

export default {
  name: 'TravelTimeMap',

  components: {
    DropdownCheckbox,
    MapboxMap,
    MapboxTravelTimeLayer,
  },

  props: {
    /** @type {import('vue').Prop<Array<SelectedPoint>>} */
    points: {
      type: Array,
      required: true,
    },

    /** @type {import('vue').Prop<import('./TravelTimeOptions.vue').TravelTimeOptions>} */
    options: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:points'],

  data: () => ({
    /** @type {Array<string>} */
    highlightStopIds: [],

    /** @type {?mapboxgl.LngLatBounds} */
    mapBounds: null,

    /** @type {mapboxgl.Map} */
    map: null,

    /** @type {boolean} */
    mapLoaded: false,

    /** @type {Array<import('@/components/ui/DropdownCheckbox.vue').CheckboxOption>} */
    routesOptions: [],
  }),

  computed: {
    /** @return {{[tripId: string]: import('@/store/gtfs').Trip}} */
    filteredTrips() {
      const routesSelected =
        this.routesOptions.filter(route => route.selected).map(route => route.value) || [];

      // get all trips that are on the selected routes :
      const filteredTrips = Object.fromEntries(
        Object.entries(this.gtfsTrips).filter(
          // eslint-disable-next-line camelcase
          ([key, { route_id }]) => routesSelected.includes(route_id) || false,
        ),
      );

      return filteredTrips;
    },

    /** @return {string} */
    gtfsId() {
      return this.$store.getters.group.current_file;
    },

    /** @return {{[routeId: string]: import('@/store/gtfs').Route}} */
    gtfsRoutes() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'routes');
    },

    gtfsStops() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'stops');
    },

    /** @return {{[tripId: string]: import('@/store/gtfs').Trip}} */
    gtfsTrips() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'trips');
    },

    /** @return {Array<import('@/components/map/MapboxMap.vue').MapStop>} */
    mapStops() {
      const routesSelected =
        this.routesOptions.filter(route => route.selected).map(route => route.value) || [];

      // get all stops that are on the selected routes :
      const stopIds = Object.values(this.filteredTrips).reduce((acc, trip) => {
        if (trip.stop_times.length === 0) return acc;
        trip.stop_times.forEach(stopTime => {
          if (routesSelected.includes(trip.route_id) && !acc.includes(stopTime.stop_id)) {
            acc.push(stopTime.stop_id);
          }
        });
        return acc;
      }, []);

      const parentStationsIds = stopIds.reduce((acc, stop) => {
        const stopParentStation = this.gtfsStops[stop]?.parent_station;
        if (stopParentStation && !acc.includes(this.gtfsStops[stopParentStation].stop_id)) {
          acc.push(this.gtfsStops[stopParentStation].stop_id);
        }
        return acc;
      }, []);

      return [...stopIds, ...parentStationsIds].map(stopId => ({
        id: stopId,
        highlight: this.highlightStopIds.includes(stopId),
      }));
    },

    /** @return {Array<import('@/components/map/MapboxMap.vue').MapTrip>} */
    mapTrips() {
      const tripIds = Object.keys(this.filteredTrips);
      return tripIds.map(id => ({ id, highlight: HighlightType.NONE }));
    },

    /** @return {Array<GeoJSON.Feature<GeoJSON.Point>>} */
    pointsSource() {
      return this.points
        .filter(p => !p.stopId)
        .map(
          (stop, index) =>
            /** @type {GeoJSON.Feature<GeoJSON.Point>} */ ({
              type: 'Feature',

              geometry: {
                type: 'Point',
                coordinates: [stop.lng, stop.lat],
              },

              properties: {},
            }),
        );
    },

    /** @return {Array<GeoJSON.Feature<GeoJSON.Polygon>>} */
    radiusSource() {
      return this.points.map(
        stop =>
          /** @type {GeoJSON.Feature<GeoJSON.Polygon>} */ ({
            type: 'Feature',

            geometry: createGeoJSONCircle([stop.lng, stop.lat], this.options.radius),
          }),
      );
    },
  },

  watch: {
    gtfsId: {
      immediate: true,
      handler(gtfsId) {
        if (gtfsId) {
          this.$store.dispatch('gtfs/getRoutesMap', { gtfsId });
          this.$store.dispatch('gtfs/getStopsMap', { gtfsId });
          this.$store.dispatch('gtfs/getTripsMap', { gtfsId });
        }
      },
    },

    // calculate routes options as soon as async gtfsRoutes is available
    gtfsRoutes: {
      immediate: true,
      handler(gtfsRoutes) {
        if (gtfsRoutes) {
          this.routesOptions = this.getRoutesOptions(gtfsRoutes);
        }
      },
    },
  },

  methods: {
    /**
     * @param {{[routeId: string]: import('@/store/gtfs').Route}} gtfsRoutes
     * @return {Array<import('@/components/ui/DropdownCheckbox.vue').CheckboxOption>}
     */
    getRoutesOptions(gtfsRoutes) {
      return Object.values(gtfsRoutes).reduce((acc, route) => {
        const routeOption = {
          label: route.route_short_name,
          value: route.route_id,
          selected: true,
        };
        acc.push(routeOption);
        return acc;
      }, []);
    },

    /** @param {mapboxgl.MapLayerMouseEvent} event */
    onMapPointEnter(event) {
      this.map.getCanvasContainer().style.cursor = 'pointer';
    },

    /** @param {mapboxgl.MapLayerMouseEvent} event */
    onMapPointLeave(event) {
      this.map.getCanvasContainer().style.cursor = '';
    },

    /**
     * @param {mapboxgl.MapLayerMouseEvent} event
     * @param {boolean} [isStop]
     */
    setPoint(event, isStop = false) {
      /** @type {SelectedPoint} */
      const point = { ...event?.lngLat };

      if (isStop && event?.features) {
        const feature = event?.features[0];
        if (feature) {
          const { geometry } = /** @type {GeoJSON.GeoJsonProperties} */ (feature);
          [point.lng, point.lat] = geometry.coordinates;
          point.stopId = feature.properties.id;
          point.stopName = feature.properties.label;
          this.highlightStopIds.push(feature.properties.id);
        }
      }

      const pointAlreadySelected = this.points.some(
        p => (p.lat === point.lat && p.lng === point.lng) || (p.stopId && p.stopId === point.stopId),
      );

      if (pointAlreadySelected) {
        // unselect point if it is already selected
        this.$emit(
          'update:points',
          this.points.filter(p => p.lat !== point.lat && p.lng !== point.lng),
        );
      } else if (this.points.length < 2) {
        this.$emit('update:points', [...this.points, point]);
      }
    },

    /** @param {{map: mapboxgl.Map}} event */
    onMapLoad({ map }) {
      map.once('idle', () => {
        this.map = map;
        this.mapLoaded = true;
      });
    },
  },
};

/**
 * @typedef {Object} SelectedPoint
 * @property {number} lat
 * @property {number} lng
 * @property {?string} [stopId]
 * @property {?string} [stopName]
 */
</script>

<style scoped lang="scss">
.travel-time-map {
  position: relative;
  height: 100%;

  &__dropdown {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: $map-dropdown;
  }
}
</style>
