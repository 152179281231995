import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { checkValidLat, checkValidLong } from '@/libs/helpers/geo';

const EditableCell = defineAsyncComponent(() => import('@/components/Table/cells/EditableCell.vue'));

export const ColumnKey = {
  NAME: 'name',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  RADIUS: 'radius',
};

export const getDatagrid = () => {
  return new DataGrid({
    name: 'depotListDatagrid',
    searchFields: [ColumnKey.NAME],
    defaultSortBy: [{ key: ColumnKey.NAME, order: SortOrder.ASC }],
    rowIdKey: 'id',
    hasActions: true,
    showColumnSelector: false,
    columns: [
      new DataGridColumn({
        key: ColumnKey.NAME,
        title: 'column.depotName',
        selectable: false,
        cellBuilder([value, object], { valueChanged, isValidValue, editMode, placeholder }) {
          return {
            component: EditableCell,
            events: { valueChanged, isValidValue },
            props: {
              placeholder,
              _id: object.id,
              value,
              checkIsRequiredAndEmpty: true,
              editMode,
              type: 'name',
            },
          };
        },
      }),
      new DataGridColumn({
        key: ColumnKey.LATITUDE,
        title: 'column.latitude',
        selectable: false,
        cellBuilder([value, object], { valueChanged, isValidValue, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged, isValidValue },
            props: {
              placeholder: '0.0',
              _id: object.id,
              value: object.location.latitude,
              checkIsRequiredAndEmpty: true,
              editMode,
              type: 'location.latitude',
              formatOptions: { inputType: 'number' },
              validation: value => value !== 0 && checkValidLat(value),
            },
          };
        },
      }),
      new DataGridColumn({
        key: ColumnKey.LONGITUDE,
        title: 'column.longitude',
        selectable: false,
        cellBuilder([value, object], { valueChanged, isValidValue, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged, isValidValue },
            props: {
              placeholder: '0.0',
              _id: object.id,
              value: object.location.longitude,
              checkIsRequiredAndEmpty: true,
              editMode,
              type: 'location.longitude',
              formatOptions: { inputType: 'number' },
              validation: value => value !== 0 && checkValidLong(value),
            },
          };
        },
      }),
      new DataGridColumn({
        key: ColumnKey.RADIUS,
        title: 'column.radius',
        selectable: false,
        cellBuilder([value, object], { valueChanged, isValidValue, editMode }) {
          return {
            component: EditableCell,
            events: { valueChanged, isValidValue },
            props: {
              placeholder: '50',
              _id: object.id,
              value,
              checkIsRequiredAndEmpty: true,
              editMode,
              type: 'radius',
              formatOptions: { maxLength: 4, inputType: 'number' },
              validation: value => value > 0,
            },
          };
        },
      }),
    ],
  });
};
