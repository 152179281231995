<script>
/** @enum {string} */
export const ValidationStatus = {
  IN_PROGRESS: 'in-progress',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
};
</script>

<script setup>
import { useI18n } from 'vue-i18n';
import { ref, computed, watch } from 'vue';
import { Severity, MapTypeSeverity } from './gtfsValidationModel.js';
import Btn from '@/components/ui/Btn.vue';

const { t } = useI18n();

const props = defineProps({
  validationInProgress: {
    required: true,
    type: Boolean,
  },
  /** @type {import('vue').Prop<Object>} */
  validationData: {
    default: () => ({}),
    type: Object,
  },
});

const emit = defineEmits(['checkGtfs', 'validationStatus']);

/** @type {import('vue').Ref<Boolean>} */
const validationInProgress = ref(props.validationInProgress);

/** @return {IssuesBySeverity} */
const issueTypes = computed(() => {
  const validations = props.validationData.validations || {};
  return Object.keys(validations).reduce(
    (acc, type) => {
      const typeCount = { name: type, count: validations[type].length };
      if (MapTypeSeverity[Severity.ERROR].includes(type) || MapTypeSeverity[Severity.FATAL].includes(type)) {
        acc.errors.push(typeCount);
      } else if (
        MapTypeSeverity[Severity.WARNING].includes(type) ||
        MapTypeSeverity[Severity.INFORMATION].includes(type)
      ) {
        acc.alerts.push(typeCount);
      }
      return acc;
    },
    {
      errors: [],
      alerts: [],
    },
  );
});

/** @return {ValidationStatus} */
const validationStatus = computed(() => {
  if (props.validationInProgress) {
    return ValidationStatus.IN_PROGRESS;
  } else if (issueTypes.value.errors.length > 0) {
    return ValidationStatus.ERROR;
  } else if (issueTypes.value.alerts.length > 0) {
    return ValidationStatus.WARNING;
  }
  return ValidationStatus.SUCCESS;
});

/** @return {Header} */
const getHeader = () => {
  const errorCount = issueTypes.value.errors.length;
  const alertCount = issueTypes.value.alerts.length;
  const header = {};
  if (props.validationInProgress) {
    header.title = t('gtfsValidationInProgress');
    header.info = t('pleaseWait');
    header.icon = 'fa fa-circle-notch fa-spin';
  } else if (errorCount > 0 && !alertCount) {
    header.title = t('errorsOnly', errorCount);
    header.info = t('youCannotPublish');
    header.icon = 'fa fa-times-circle';
  } else if (!errorCount && alertCount > 0) {
    header.title = t('alertsOnly', alertCount);
    header.info = t('youShouldFix');
    header.icon = 'fa fa-exclamation-circle';
  } else if (errorCount > 0 && alertCount > 0) {
    const errorText = errorCount > 1 ? t('errors') : t('error');
    const alertText = alertCount > 1 ? t('warnings') : t('warning');
    header.title = t('errorsAndWarnings', {
      errorCount,
      error: errorText,
      alertCount,
      alert: alertText,
    });
    header.info = t('youCannotPublish');
    header.icon = 'fa fa-times-circle';
  } else {
    header.title = t('isConform');
    header.info = t('youCanPublish');
    header.icon = 'fa fa-check-circle';
  }
  return header;
};

// Emit validation status when updated :
watch([validationInProgress, issueTypes], () => {
  emit('validationStatus', validationStatus.value);
});

/**
 * @typedef {Object} IssuesBySeverity
 * @property {Array<IssueTypeCount>} errors
 * @property {Array<IssueTypeCount>} alerts
 */

/**
 * @typedef {Object} IssueTypeCount
 * @property {string} name
 * @property {number} count
 */

/**
 * @typedef {Object} Header
 * @property {string} title
 * @property {string} info
 * @property {string} icon
 */
</script>

<template>
  <div class="gtfs-validation" :class="`gtfs-validation--${validationStatus}`">
    <div class="header">
      <div class="header__icon">
        <v-icon :class="`header-icon header-icon--${validationStatus}`">
          {{ getHeader().icon }}
        </v-icon>
      </div>
      <div class="header__text">
        <div class="header__title">{{ getHeader().title }}</div>
        <div class="header__info">{{ getHeader().info }}</div>
      </div>
      <div v-if="validationStatus !== 'success'" class="header__left">
        <img v-if="props.validationInProgress" src="@/assets/img/verify-gtfs.svg?url" alt="verifying-gtfs" />
        <Btn v-else type="secondary" @click="emit('checkGtfs')">
          <v-icon>fa fa-eye</v-icon>
          <span>{{ t('checkGtfs') }}</span>
        </Btn>
      </div>
    </div>
    <div v-if="issueTypes.errors.length > 0 || issueTypes.alerts.length > 0" class="list">
      <!-- Errors & fatal errors -->
      <div v-for="(error, index) in issueTypes.errors" :key="index" class="list__item list__item--error">
        <v-icon>fa fa-times-circle</v-icon>
        {{ t(`validations.${error.name}`) }} ({{ error.count }})
      </div>

      <!-- Alerts & informations -->
      <div v-for="(alert, index) in issueTypes.alerts" :key="index" class="list__item list__item--alert">
        <v-icon>fa fa-exclamation-circle</v-icon>
        <span>{{ t(`validations.${alert.name}`) }} ({{ alert.count }})</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.gtfs-validation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  margin-top: 15px;
  border: 1px solid black;
  border-radius: 12px;

  @media screen and (max-height: 750px) {
    height: 210px;
  }

  &--in-progress {
    border-color: $border;
    background-color: $canvas;
    color: $text-dark;

    .header__info {
      color: $text-neutral;
    }
  }

  &--warning {
    border-color: $warn;
    background-color: $warn-flat;
    color: $warn;
  }

  &--error {
    border-color: $danger;
    background-color: $light-red;
    color: $danger;
  }

  &--success {
    border-color: $primary-light;
    background-color: $green-flat;
    color: $primary-light;
  }

  .header {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height: 110px;
    padding: 20px;
    border-radius: 12px 12px 0 0;

    &__text {
      width: 90%;
      text-align: left;
    }

    &__title {
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 20px;
    }

    &__info {
      font-weight: 500;
      font-size: 14px;
    }

    &__left {
      img {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 200px;
      }
    }

    .header-icon {
      margin-right: 20px;
      font-size: 40px;

      &--in-progress {
        color: $primary-light;
      }

      &--warning {
        color: $warn;
      }

      &--error {
        color: $danger;
      }
    }
  }

  .list {
    overflow: auto;
    border-top: 1px solid $border;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: $white;
    color: $text-dark;
    font-weight: 500;
    font-size: 13px;
    text-align: left;

    &::-webkit-scrollbar {
      display: none;
    }

    .v-icon {
      margin-right: 5px;
      font-size: 14px;
    }

    .list__item {
      height: 40px;
      padding: 5px 20px;
      line-height: 2.5;

      &:not(:last-child) {
        border-bottom: 1px solid $background-variant;
      }

      &--error {
        .v-icon {
          color: $danger;
        }
      }

      &--alert {
        .v-icon {
          color: $warn;
        }
      }
    }
  }

  .ui-btn {
    white-space: nowrap;

    .v-icon {
      display: inline;
      margin-right: 8px;
      font-size: 16px;
    }
  }
}
</style>

<i18n locale="fr">
{
  "checkGtfs": "Consulter",
  "gtfsValidationInProgress": "Vérification du plan de transport",
  "pleaseWait": "Veuillez patienter quelques instants.",
  "error": "erreur",
  "errors": "erreurs",
  "warning": "alerte",
  "warnings": "alertes",
  "isConform": "Votre plan de transport est conforme !",
  "alertsOnly": "Votre plan de transport comporte {count} alerte. | Votre plan de transport comporte {count} alertes.",
  "errorsOnly": "Votre plan de transport comporte {count} erreur. | Votre plan de transport comporte {count} erreurs.",
  "errorsAndWarnings": "Votre plan de transport comporte {errorCount} {error} et {alertCount} {alert}.",
  "youCanPublish": "Vous pouvez maintenant le publier.",
  "youShouldFix": "Vous pouvez le publier, mais il est recommandé de le corriger.",
  "youCannotPublish": "Il doit être corrigé avant d’être publié.",
  "validations" : {
    "UnusedStop": "Un arrêt n'est pas utilisé",
    "Slow": "La vitesse entre deux arrêts est trop lente",
    "ExcessiveSpeed": "La vitesse entre deux arrêts est trop élevée",
    "NegativeTravelTime": "La durée de voyage entre deux arrêts est négative",
    "CloseStops": "Deux arrêts sont très proches l'un de l'autre dans les mêmes trajets",
    "NullDuration": "La durée de voyage entre deux arrêts est nulle",
    "InvalidReference": "Référence non valide",
    "InvalidArchive": "Archive non valide",
    "MissingName": "Une agence, une ligne ou un arrêt n'a pas de nom",
    "MissingId": "Une agence, un calendrier, une ligne, un point de tracé, un arrêt ou un trajet n'a pas d'identifiant",
    "MissingCoordinates": "Un point de tracé ou un arrêt n'a pas de coordonnées",
    "InvalidCoordinates": "Les coordonnées d'un point de tracé ou d'un arrêt ne sont pas valides",
    "InvalidRouteType": "Le type d'une ligne n'est pas valide",
    "MissingUrl": "Une agence ou un éditeur de flux n'a pas d'URL",
    "InvalidUrl": "L'URL d'une agence ou d'un éditeur de flux n'est pas valide",
    "InvalidTimezone": "Le fuseau horaire d'une agence n'est pas valide",
    "DuplicateStops": "Deux points d'arrêt ou zones d'arrêt sont identiques",
    "MissingPrice": "Un tarif est manquant",
    "InvalidCurrency": "La devise d'un tarif n'est pas valide",
    "InvalidTransfers": "Le nombre de correspondances d'un tarif n'est pas valide",
    "InvalidTransferDuration": "La durée de correspondance d'un tarif n'est pas valide",
    "MissingLanguage": "Le code de langue de l'éditeur est manquant",
    "InvalidLanguage": "Le code de langue de l'éditeur n'est pas valide",
    "DuplicateObjectId": "Deux objets possèdent le même identifiant",
    "UnloadableModel": "Une erreur fatale s'est produite lors de la création des liens dans le modèle",
    "MissingMandatoryFile": "Fichier obligatoire manquant",
    "ExtraFile": "Un fichier n'appartient pas à une archive GTFS",
    "ImpossibleToInterpolateStopTimes": "Il est impossible d'interpoler le départ/l'arrivée de certains horaires de l'itinéraire",
    "InvalidStopLocationTypeInTrip": "Type de localisation d'arrêt invalide dans l'itinéraire. Seuls les points d'arrêt sont autorisés à être utilisés dans un itinéraire",
    "InvalidStopParent": "La station parente d'un arrêt n'est pas valide",
    "IdNotAscii": "Un identifiant n'est pas en encodage ASCII",
    "InvalidShapeId": Un identifiant de tracé référencé dans trips.txt n'existe pas",
    "UnusedShapeId": "Un identifiant de tracé défini dans shapes.txt n'est utilisé nulle part ailleurs",
    "DuplicateStopSequence": "Séquence d'arrêt en double dans l'itinéraire",
    "SubFolder": "Les fichiers .txt dans le GTFS sont situés dans un sous-dossier, ce qui est désormais explicitement interdit par la spécification",
    "TripsWithoutShapes": "Des trajets n'ont pas de tracés associés",
    "TripsWithoutDistances": "Des trajets n'ont pas de distances associées",
    "ValidationDisabled": "La validation de GTFS est désactivée"
  }
}
</i18n>

<i18n locale="en">
{
  "checkGtfs": "Check plan",
  "gtfsValidationInProgress": "Transport plan validation check",
  "pleaseWait": "Please wait a few moments.",
  "error": "error",
  "errors": "errors",
  "warning": "warning",
  "warnings": "warnings",
  "isConform": "Your transport plan is conform!",
  "alertsOnly": "Your transport plan has {count} alert. | Your transport plan has {count} alerts.",
  "errorsOnly": "Your transport plan has {count} error. | Your transport plan has {count} errors.",
  "errorsAndWarnings": "Your transport plan has {errorCount} {error} and {alertCount} {alert}.",
  "youCanPublish": "You can now publish it.",
  "youShouldFix": "You can publish it, but it is recommended to fix it.",
  "youCannotPublish": "It must be fixed before being published.",
  "validations" : {
    "UnusedStop": "A stop is not used",
    "Slow": "The speed between two stops is too low",
    "ExcessiveSpeed": "The speed between two stops is too high",
    "NegativeTravelTime": "The travel duration between two stops is negative",
    "CloseStops": "Two stops very close to each other in the same trips",
    "NullDuration": "The travel duration between two stops is null",
    "InvalidReference": "Reference not valid",
    "InvalidArchive": "Archive not valid",
    "MissingName": "An agency, a route or a stop has its name missing",
    "MissingId": "An agency, a calendar, a route, a shape point, a stop or a trip has its Id missing",
    "MissingCoordinates": "A shape point or a stop is missing its coordinate(s)",
    "InvalidCoordinates": "The coordinates of a shape point or a stop are not valid",
    "InvalidRouteType": "The type of a route is not valid",
    "MissingUrl": "An agency or a feed publisher is missing its URL",
    "InvalidUrl": "The URL of an agency or a feed publisher is not valid",
    "InvalidTimezone": "The TimeZone of an agency is not valid",
    "DuplicateStops": "Two stop points or stop areas are identical",
    "MissingPrice": "A fare is missing its price",
    "InvalidCurrency": "The currency of a fare is not valid",
    "InvalidTransfers": "The number of transfers of a fare is not valid",
    "InvalidTransferDuration": "The transfer duration of a fare is not valid",
    "MissingLanguage": "The publisher language code is missing",
    "InvalidLanguage": "The publisher language code is not valid",
    "DuplicateObjectId": "Two objects have the same id",
    "UnloadableModel": "A fatal error has occured by building the links in the model",
    "MissingMandatoryFile": "Mandatory file missing",
    "ExtraFile": "The file does not belong to a GTFS archive",
    "ImpossibleToInterpolateStopTimes": "It's impossible to interpolate the departure/arrival of some stoptimes of the trip",
    "InvalidStopLocationTypeInTrip": "Invalid Stop Location type in trip. Only Stop Points are allowed to be used in a Trip",
    "InvalidStopParent": "The parent station of this stop is not a valid one",
    "IdNotAscii": "One Id is not in ASCII encoding",
    "InvalidShapeId": "The shape id referenced in trips.txt does not exist",
    "UnusedShapeId": "A shape id defined in shapes.txt is not used elsewhere",
    "DuplicateStopSequence": "Duplicate stop sequence in trip",
    "SubFolder": "The .txt files within the GTFS are located in a subfolder, which is now explicitly forbidden by the specification",
    "TripsWithoutShapes": "Some trips have no shapes",
    "TripsWithoutDistances": "Some trips have no distances",
    "ValidationDisabled": "GTFS validation is disabled"
  }
}
</i18n>
