/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

/** @module Trips */
import deepmerge from 'deepmerge';

import Api from '@/api';
import { dateGtfsFormatToObj, dateObjToGtfsFormat, timestampMidnight } from '@/libs/helpers/dates';
import { distanceCoords } from '@/libs/helpers/geo';
import { Deferred } from '@/libs/deferred';

/** @enum {string} */
export const CheckedStatus = {
  DONE: 'DONE',
  NO_DATA: 'NO_DATA',
  NOT_DONE: 'NOT_DONE',
  PROBLEM: 'PROBLEM',
};

/** @enum {string} */
export const ModalType = {
  COMMENT: 'comment',
  MESSAGES: 'messages',
  STOP_INFO: 'stop_info',
};

/** @enum {string} */
export const ScheduleRelationship = {
  CANCELED: 'CANCELED',
  SCHEDULED: 'SCHEDULED',
  SKIPPED: 'SKIPPED',
};

/** @enum {string} */
export const Status = {
  ENDED: 'ended',
  RUNNING: 'running',
  TO_BE_DONE: 'toBeDone',
};

/** @enum {string} */
export const StopTimeEvent = {
  ARRIVAL: 'arrival',
  DEPARTURE: 'departure',
};

/** @type {{[key: string]: Deferred<void>}} */
const loadingPromises = {};

/**
 * Check if a trip time is in an interval.
 * @param {import('./gtfs').Trip} trip
 * @param {number} fromTime
 * @param {number} [toTime]
 * @return {boolean} True when the trip either starts or ends in the interval, bounds not included.
 */
const isTripInInterval = (trip, fromTime, toTime) => {
  if (!fromTime && !toTime) return true;
  if (!trip.stop_times || trip.stop_times.length === 0) return false;

  const firstTime = trip.stop_times[0].arrival_time;
  const lastTime = trip.stop_times[trip.stop_times.length - 1].departure_time;
  if (!fromTime) {
    return firstTime < toTime;
  }
  if (!toTime) {
    return fromTime < lastTime;
  }
  return fromTime < lastTime || firstTime < toTime;
};

/** @typedef {typeof state} State */
const state = {
  /** @type {Object.<string, boolean>} */
  loading: {},

  /** @type {{[dateGtfs: string]: {[gtfsTripId: string]: {[deviceId: string]: VkHistory}}}} */
  vkHistory: {},
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,
  state,

  getters: {
    /**
     * @callback DeviceOnTripsCallback
     * @param {string} startDate
     * @param {boolean} today
     * @return {{[tripId: string]: string}}
     */
    /** @return {DeviceOnTripsCallback} */
    deviceOnTrips: (state, getters, rootState, rootGetters) => (startDate, today) =>
      Object.values(rootGetters['devices/onlineDevices']).reduce((acc, device) => {
        const isOnTripToday =
          'trip' in device
            ? device.trip && device.trip.trip_id != null && device.trip.start_date === startDate
            : device.trip_id != null && today;

        if (isOnTripToday) {
          acc[device.trip_id] = device.device_id;
        }

        return acc;
      }, {}),

    isLoading(state) {
      return Object.values(state.loading).some(v => v);
    },

    /**
     * Sort trips by status: ENDED/RUNING/TO_BE_DONE
     * @callback TripsStatusGetter
     * @param {Array<import('./gtfs').Trip>} trips
     * @param {string} [startDate=today] Start date of the trips in GTFS format (YYYYMMDD)
     * @return {{[tripId: string]: Status}} Map<TripId, Status>.
     * TODO: [Clean] sort by [gtfsId][tripId]
     */
    /** @return {TripsStatusGetter} */
    tripsStatus: async (state, getters, dispatch) => {
      const group = await dispatch('getGroup', null, { root: true });

      return (trips, startDate) => {
        const tripsStatusObject = /** @type {{[tripId: string]: Status}} */ ({});
        const now = new Date();
        const nowTs = now.getTime() / 1000;
        const nowStartDate = dateObjToGtfsFormat(now);
        startDate = startDate || nowStartDate;
        const midnight = timestampMidnight(dateGtfsFormatToObj(startDate), group.tz);
        const today = startDate === nowStartDate;

        /** @type {ReturnType<DeviceOnTripsCallback>} */
        const deviceOnTrips = getters.deviceOnTrips(startDate, today);

        // get component trips status
        trips.forEach(trip => {
          let status;

          if (deviceOnTrips[trip.trip_id]) {
            status = Status.RUNNING;
          } else {
            const arrivalTime = trip.stop_times[trip.stop_times.length - 1].arrival_time;
            const departureTime = trip.stop_times[0].arrival_time;

            if (nowTs > midnight + arrivalTime) {
              status = Status.ENDED;
            } else if (nowTs > midnight + departureTime) {
              status = Status.RUNNING;
            } else {
              status = Status.TO_BE_DONE;
            }
          }

          tripsStatusObject[trip.trip_id] = status;
        });

        return tripsStatusObject;
      };
    },
  },

  mutations: {
    /** Clear state and cache. */
    clear(state) {
      state.loading = {};
      state.vkHistory = {};
    },

    /**
     * Set the vkHistory
     * @param state
     * @param {Object} payload
     * @param {string} payload.dateGtfs
     * @param {Object<string, Object<string, VkHistory>>} payload.vkHistory - Map<GtfsIdTripId, Map<DeviceId, VkHistory>>.
     */
    setVkHistory(state, { dateGtfs, vkHistory }) {
      state.vkHistory[dateGtfs] = vkHistory;
    },

    setLoading(state, data) {
      Object.keys(data).forEach(key => {
        state.loading[key] = data[key];
        if (data[key] && !loadingPromises[key]) {
          loadingPromises[key] = new Deferred();
        } else {
          loadingPromises[key].resolve();
        }
      });
    },

    updateVkHistoryElements(state, updates) {
      state.vkHistory = deepmerge(state.vkHistory, updates, {
        arrayMerge: (_, newArr) => newArr,
      });
    },
  },

  actions: {
    /**
     * Clear state.
     */
    clear({ commit }) {
      commit('clear');
    },

    /**
     * Get trips for each GTFS published on a day.
     * @param context
     * @param {Object} payload
     * @param {string} payload.dateGtfs
     * @return {Promise<{[gtfsId: string]: {[tripId: string]: import('./gtfs').Trip}}>}
     */
    async getPublishedTripsMapOn({ dispatch }, { dateGtfs }) {
      const timestamp = dateGtfsFormatToObj(dateGtfs).getTime() / 1000;
      /** @type {Array<import('./gtfs').Publication>} */
      const published = await dispatch(
        'gtfs/getGtfsPublicationsIn',
        { fromTs: timestamp, toTs: timestamp + 86400 },
        { root: true },
      );

      // Get filtered trips for each publication
      published.sort((a, b) => a.ts - b.ts);
      const filteredTrips = await Promise.all(
        published.map(async (p, i) => {
          const gtfsId = p.current_file;
          let fromTime = 0;
          let toTime;

          if (i > 0) {
            fromTime = p.ts - timestamp;
          }
          if (i < published.length - 1) {
            toTime = published[i + 1].ts - timestamp;
          }

          return {
            gtfsId,
            trips: /** @type {{[tripId: string]: import('./gtfs').Trip}} */ (
              await dispatch('getTripsInterval', {
                gtfsId,
                dateGtfs,
                fromTime,
                toTime,
              })
            ),
          };
        }),
      );

      // Merge trips of same GtfsId
      const pubTrips = /** @type {{[gtfsId: string]: {[tripId: string]: import('./gtfs').Trip}}} */ ({});
      filteredTrips.forEach(ft => {
        if (!pubTrips[ft.gtfsId]) pubTrips[ft.gtfsId] = {};
        Object.assign(pubTrips[ft.gtfsId], ft.trips);
      });

      return pubTrips;
    },

    /**
     * Get active trips. Can filter on a time interval.
     * @param context
     * @param {Object} payload
     * @param {string} payload.gtfsId
     * @param {string} payload.dateGtfs
     * @param {number} [payload.fromTime] - Lower limit to include, in seconds after midnight
     * @param {number} [payload.toTime] - Upper limit to include, in seconds after midnight
     * @return {Promise<{[tripId: string]: import('./gtfs').Trip}>}
     */
    async getTripsInterval({ dispatch }, { gtfsId, dateGtfs, fromTime = 0, toTime }) {
      const [trips, services] = await Promise.all([
        dispatch('gtfs/getTripsMap', { gtfsId }, { root: true }),
        dispatch('gtfs/getServicesMap', { gtfsId, dateGtfs }, { root: true }),
      ]);

      const result = /** @type {{[tripId: string]: import('./gtfs').Trip}} */ ({});
      Object.values(trips).forEach(t => {
        if (services[t.service_id] && isTripInInterval(t, fromTime, toTime)) {
          result[t.trip_id] = t; // TODO: [Clean] Convert from `Map<TripId, Trip>` to `Set<TripId>`.
        }
      });

      return result;
    },

    /**
     * Get all trips vk history.
     * @param context
     * @param {Object} payload
     * @param {string} payload.dateGtfs
     * @return {Promise<{[key: string]: {[deviceId: string]: VkHistory}}>} key is composite of gtfsId and tripId
     */
    async getVkHistory({ state, commit, dispatch }, { dateGtfs }) {
      commit('setLoading', { vkHistory: true });
      const group = await dispatch('getGroup', null, { root: true });
      const rawVkHistory = await Api.trips.getVkHistory(group._id, dateGtfs);

      const vkHistory = rawVkHistory.reduce((acc, h) => {
        const key = h.gtfs_id + h.trip_id;
        if (!acc[key]) acc[key] = {};
        acc[key][h.device_id] = h;

        return acc;
      }, {});

      commit('setVkHistory', { dateGtfs, vkHistory });
      commit('setLoading', { vkHistory: false });

      return state.vkHistory[dateGtfs];
    },

    /**
     * Change an existing trip.
     * @param context
     * @param {Object} update
     * @return {Promise<Response>}
     */
    async updateTrip({ state, commit, dispatch }, { query, body, many }) {
      const group = await dispatch('getGroup', null, { root: true });
      return Api.trips.updateTrip(group._id, query, body, many);
    },

    /**
     * Update vkHistory from received messages.
     * @param context
     * @param {Array<import('./devices').Device>} messages
     */
    async updateVkHistories({ state, commit, rootState, rootGetters }, messages) {
      const updates =
        /** @type {{[dateGtfs: string]: {[gtfsTripId: string]: {[deviceId: string]: Partial<VkHistory>}}}} */ ({});

      messages.forEach(message => {
        const deviceId = message.device_id;
        const device = rootState.devices.list[deviceId];
        if (!device) return;

        const dateGtfs =
          ('trip' in device && device.trip && device.trip.start_date) ||
          dateObjToGtfsFormat(new Date(message.ts * 1000));
        if (!state.vkHistory[dateGtfs] || !message.latlng) return;

        /**
         * @param {keyof import('./devices').Device} k
         * @return {import('./devices').Device[k]}
         */
        const getLastValue = k => (Object.prototype.hasOwnProperty.call(message, k) ? message[k] : device[k]);

        if (getLastValue('trip_pending')) return;

        const tripId = getLastValue('trip_id');
        if (!tripId) return;

        const gtfsId = device.gtfs_id || rootGetters.group.current_file;

        let vkHistoryElement;
        if ((state.vkHistory[dateGtfs][gtfsId + tripId] || {})[deviceId]) {
          const currentVkHistoryElement = state.vkHistory[dateGtfs][gtfsId + tripId][deviceId];
          vkHistoryElement = /** @type {Partial<VkHistory>} */ ({});
          vkHistoryElement.end_latlng = message.latlng;
          vkHistoryElement.end_ts = message.ts;

          const dd =
            distanceCoords(
              { lat: device.latlng[0], lng: device.latlng[1] },
              { lat: message.latlng[0], lng: message.latlng[1] },
            ) / 1000;
          vkHistoryElement.vk = currentVkHistoryElement.vk + dd;

          if (tripId && getLastValue('current_status') === null) {
            vkHistoryElement.vk_no_status = (currentVkHistoryElement.vk_no_status || 0) + dd;
          }
        } else {
          vkHistoryElement = {
            date: dateGtfs,
            device_id: deviceId,
            end_latlng: message.latlng,
            end_ts: message.ts,
            gtfs_id: gtfsId,
            start_latlng: message.latlng,
            start_ts: message.ts,
            trip_id: tripId,
            vk: 0,
          };
        }

        // vkHistory is of the form vkHistory[dateGtfs][gtfsId + tripId][deviceId]
        let currentLevel = updates;
        [dateGtfs, gtfsId + tripId, deviceId].forEach(k => {
          // Create missing nested keys
          if (!currentLevel[k]) currentLevel[k] = {};
          currentLevel = currentLevel[k];
        });

        updates[dateGtfs][gtfsId + tripId][deviceId] = vkHistoryElement;
      });

      if (Object.keys(updates).length > 0) {
        commit('updateVkHistoryElements', updates);
      }
    },
  },
});

/**
 * @typedef {Object} TripHistory
 * @property {string} _id
 * @property {string} end_date
 * @property {string} gtfs_id
 * @property {string} start_date
 * @property {string} trip_id
 * @property {CheckedStatus} [checked_status]
 * @property {string} [comment]
 * @property {number} [delay]
 * @property {string} [schedule_relationship]
 * @property {Array<StopTimeUpdate>} [stop_time_update]
 */

/**
 * @typedef {Object} VkHistory
 * @property {string} date - In GTFS format
 * @property {string} device_id
 * @property {number} [end_ts]
 * @property {string} gtfs_id
 * @property {number} start_ts
 * @property {string} trip_id
 * @property {string} team_id
 * @property {number} vk - Recorded kilometers
 * @property {number} [vk_no_status]
 * @property {Array<number>} [start_latlng]
 * @property {Array<number>} [end_latlng]
 */
