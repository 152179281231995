import dayjs from 'dayjs';
import { ServiceExceptionType, daysOfWeek } from '@/store/gtfs';
import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';
import store from '@/store';
import type { Service, ServiceException } from '@/@types/gtfs';

export interface InactiveDates {
  minDate: Date | null;
  maxDate: Date | null;
  isDateInactive: (date: Date) => boolean;
  isDateActiveException: (date: Date) => boolean;
}
export default {
  /**
   * Return the inactive dates for a trip from the service Id
   */
  async getInactiveDatesForATrip(serviceId: string, gtfsId: string): Promise<InactiveDates> {
    const calendar: { [serviceId: string]: Service } = await store.dispatch('gtfs/getCalendar', {
      gtfsId,
    });
    const calendarDates: Array<ServiceException> = await store.dispatch('gtfs/getCalendarDates', {
      gtfsId,
    });
    const exceptionsRemoved: { [gtfsDate: string]: boolean } = {};
    const exceptionsAdd: { [gtfsDate: string]: boolean } = {};

    const inactiveDates: InactiveDates = {
      minDate: null,
      maxDate: null,
    } as InactiveDates;

    calendarDates.forEach(exception => {
      if (exception.service_id === serviceId && exception.exception_type === ServiceExceptionType.REMOVE) {
        exceptionsRemoved[exception.date] = true;
      }

      if (exception.service_id === serviceId && exception.exception_type === ServiceExceptionType.ADD) {
        exceptionsAdd[exception.date] = true;
      }
    });
    if (calendar[serviceId]?.end_date) {
      inactiveDates.maxDate = dateGtfsFormatToObj(calendar[serviceId].end_date);
    }
    if (calendar[serviceId]?.start_date) {
      inactiveDates.minDate = dateGtfsFormatToObj(calendar[serviceId].start_date);
    }

    // 1 year limit before and after today's date
    const todayPlusOneYear = dayjs(new Date()).add(1, 'year').toDate();
    const todayMinusOneYear = dayjs(new Date()).subtract(1, 'year').toDate();
    if (!inactiveDates.maxDate || inactiveDates.maxDate.getTime() > todayPlusOneYear.getTime()) {
      inactiveDates.maxDate = todayPlusOneYear;
    }
    if (!inactiveDates.minDate || inactiveDates.minDate.getTime() < todayMinusOneYear.getTime()) {
      inactiveDates.minDate = todayMinusOneYear;
    }

    inactiveDates.isDateInactive = date => {
      const dateGtfs = dateObjToGtfsFormat(date);
      if (exceptionsRemoved[dateGtfs]) return true;
      if (exceptionsAdd[dateGtfs]) return false;
      return !calendar[serviceId] || !calendar[serviceId][daysOfWeek[date.getDay()] as keyof Service];
    };

    inactiveDates.isDateActiveException = date => {
      const dateGtfs = dateObjToGtfsFormat(date);
      return exceptionsAdd[dateGtfs];
    };

    return inactiveDates;
  },

  async isTripActiveOnDate(gtfsId: string, serviceId: string, date: Date): Promise<boolean> {
    const inactiveDates = await this.getInactiveDatesForATrip(serviceId, gtfsId);
    const isActiveBefore = inactiveDates.minDate ? inactiveDates.minDate?.getTime() < date.getTime() : true;
    const isActiveAfter = inactiveDates.maxDate ? date.getTime() < inactiveDates.maxDate?.getTime() : true;
    const isInActivePeriod = isActiveBefore && isActiveAfter;

    // calendar_dates are valid, even if outside the active period
    if (isInActivePeriod) {
      return !inactiveDates.isDateInactive(date);
    }

    return inactiveDates.isDateActiveException(date);
  },
};
