export function getDrawTheme(routeColor) {
  return [
    {
      id: 'gl-draw-line-active-background',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#000',
        'line-width': ['case', ['==', ['get', 'id'], 'shape'], 5, 7],
      },
    },
    {
      id: 'gl-draw-line-active',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': ['case', ['==', ['get', 'id'], 'shape'], routeColor, '#F99C49'],
        'line-width': 4,
      },
    },
    {
      id: 'gl-draw-line-active-arrows',
      type: 'symbol',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
      layout: {
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'icon-image': 'shape_arrow',
        'icon-size': 0.25,
        'symbol-placement': 'line',
        'symbol-spacing': 30,
      },
    },
    {
      id: 'gl-draw-new-point',
      type: 'circle',
      filter: ['all', ['==', 'isNew', true]],
      paint: {
        'circle-color': '#FFF5EC',
        'circle-radius': 7,
        'circle-opacity': 1,
        'circle-stroke-color': '#000',
        'circle-stroke-width': 2,
        'circle-stroke-opacity': 1,
      },
    },
    {
      id: 'gl-draw-new-point-text',
      type: 'symbol',
      filter: ['all', ['==', 'isNew', true]],
      layout: {
        'text-allow-overlap': true,
        'text-anchor': 'center',
        'text-field': ['get', 'isNewIndex'],
        'text-size': 10,
        'text-font': ['Poppins Bold'],
      },
    },
    {
      id: 'gl-draw-point-selected',
      type: 'circle',
      filter: ['any', ['==', 'selectedPoint', true], ['==', 'isLimitPoint', true]],
      paint: {
        'circle-color': '#fff',
        'circle-radius': 5,
        'circle-stroke-color': '#000',
        'circle-stroke-width': 1,
      },
    },
  ];
}
