<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { Permission } from '@/auth';
import { GroupRoute } from '@/libs/routing';

import CardHeader from './CardHeader.vue';

const router = useRouter();
const store = useStore();

const props = defineProps({
  noGtfs: {
    default: false,
    type: Boolean,
  },
});

const activeIntegrations = computed(() => {
  return store.getters['integrations/activeIntegrations'];
});

/** @param {String} route */
function isDisabled(route) {
  return route === GroupRoute.INTEGRATIONS && !store.getters.hasPermission(Permission.VIEW_INTEGRATIONS);
}

const features = computed(() => {
  return [
    {
      active: !props.noGtfs,
      route: GroupRoute.GTFS_RT,
    },
    {
      active: store.state.groupServerSide.pub_screen,
      route: GroupRoute.PASSENGERS_SCREEN,
    },
    {
      active: store.state.groupServerSide.pub,
      route: GroupRoute.PASSENGERS_APP,
    },
    {
      active: activeIntegrations.value.length > 0,
      route: GroupRoute.INTEGRATIONS,
      subtitle: activeIntegrations.value.length > 0 ? activeIntegrations.value.length.toString() : '',
    },
  ];
});
</script>

<template>
  <v-card class="features-card" elevation="0">
    <CardHeader
      v-for="(el, index) in features"
      :key="index"
      class="features-card__item"
      :title="$t(el.route)"
      :is-active="el.active && !noGtfs"
      :subtitle="el.subtitle"
      :disabled="isDisabled(el.route)"
      show-status-indicator
      @click="router.push({ name: el.route })"
    />
  </v-card>
</template>

<style lang="scss">
.features-card {
  &__item {
    height: 46px;

    @media (min-width: 1400px) {
      height: 71px;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}
</style>

<i18n locale="fr">
  {
    "gtfs-rt": "GTFS-RT",
    "passengers-screen": "Affichage voyageur",
    "passengers-app": "Application voyageur",
    "integrations": "Intégrations"
  }
</i18n>

<i18n locale="en">
  {
    "gtfs-rt": "GTFS-RT",
    "passengers-screen": "Passengers display",
    "passengers-app": "Passengers app",
    "integrations": "Integrations"
  }
</i18n>
