<script setup lang="ts">
import type { Group } from '@/@types/group';
import {
  dateGtfsFormatToObj,
  timeHHmmToTimeObj,
  timeObjToTimestamp,
  timestampMidnight,
  timestampToTimeObj,
  type TimeObject,
} from '@/libs/helpers/dates';
import { isDeepEqual } from '@/libs/helpers/objects';
import { useTripUpdates } from '@/store-pinia/trip-updates';

import Btn from '@/components/ui/Btn.vue';
import VueDatepicker, { type DatePickerInstance } from '@vuepic/vue-datepicker';
import cloneDeep from 'clone-deep';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

const emit = defineEmits(['showBlockedModal']);

const props = defineProps({
  date: {
    required: true,
    type: String,
  },
  defaultTime: {
    type: Number,
    required: true,
  },
  stopSequence: {
    required: true,
    type: Number,
  },
});

const store = useStore();
const tripUpStore = useTripUpdates();

const textInputOptions = {
  format: 'HH:mm',
  enterSubmit: false,
};

const applyToNextStops = ref<boolean>(false);
const updatedTime = ref<TimeObject>();
const delayTimePicker = ref<DatePickerInstance>(null);
const valueOnTimepickerOpen = ref<TimeObject>();
const baseTime = ref<TimeObject>();
const initialSeconds = ref<number>(0);

const group = computed<Group>(() => {
  return store.getters.group;
});

const midnight = computed<number>(() => {
  const date = dateGtfsFormatToObj(props.date) || new Date();
  return timestampMidnight(date, group.value.tz);
});

const hasTimeChange = computed<boolean>(() => !isDeepEqual(updatedTime.value, baseTime.value));

const timeDifference = computed<number>(() => {
  if (!updatedTime.value || !baseTime.value) {
    return 0;
  }
  const baseTimeTs = timeObjToTimestamp(props.date, baseTime.value);
  let updatedTimeTs = timeObjToTimestamp(props.date, updatedTime.value);

  // Get seconds from updated time to compare them with initial seconds.
  // if initial time is 10:37:30 and with the timepicker you select 10:38, then the value of updatedTime will be 10:38:00.
  // To fix this, we add to the updatedTime the previously known seconds -> 10:38:30
  const updatedTimeSeconds = dayjs.unix(updatedTimeTs).second();
  // if updatedTime doesn't have initial seconds from stopTime, re-add them.
  // When selecting a time with timepicker, they are removed since we use hh:mm format and the lib does not handle this.
  if (updatedTimeSeconds !== initialSeconds.value) updatedTimeTs += initialSeconds.value;

  return updatedTimeTs - baseTimeTs;
});

const timeDifferenceText = computed<string>(() => {
  let text = (timeDifference.value / 60).toString();
  if (timeDifference.value >= 0) text = `+ ${text}`;
  return `${text} min`;
});

const isTimeInError = computed<boolean>(
  () => tripUpStore.timeInError.find(e => e === props.stopSequence) !== undefined,
);

watch(
  [() => tripUpStore.delay, () => tripUpStore.delays],
  () => {
    initDefaultValue();
  },
  { deep: true },
);

onMounted(() => {
  initDefaultValue();
});

function initDefaultValue() {
  let time = props.defaultTime;

  const dateTs = (midnight.value + withDelay(time)) * 1000;
  baseTime.value = timestampToTimeObj(dateTs, { tz: group.value.tz });
  // Store initial stopTime seconds from 'hh:mm:ss' to add them later if we change time with timepicker
  initialSeconds.value = dayjs(dateTs).second();
  // init time
  const delays = (tripUpStore.getADelaysByStopSequence(props.stopSequence)?.delay || 0) * 1000;
  updatedTime.value = timestampToTimeObj(dateTs + delays, { tz: group.value.tz });

  sendTimeUpdateAsTimestampToStore();
}

/**
 * Take a time and apply delay if exist
 * */
function withDelay(time: number): number {
  if (tripUpStore.delay) {
    return time + tripUpStore.delay;
  }
  return time;
}

/**
 * send updatedTime value to populate list in store
 */
function sendTimeUpdateAsTimestampToStore() {
  if (updatedTime.value)
    tripUpStore.timeStopCalculated.set(props.stopSequence, timeObjToTimestamp(props.date, updatedTime.value));
}

function resetTimer() {
  updatedTime.value = cloneDeep(baseTime.value);
  delayTimePicker.value?.closeMenu();
  // updateInternalModelValue does not work as intended, to reset we need to close & reopen...
  delayTimePicker.value?.$nextTick(() => {
    delayTimePicker.value?.openMenu();
  });
}

function cancel() {
  initDefaultValue();
  delayTimePicker.value?.closeMenu();
}
function saveModification() {
  if (applyToNextStops.value) tripUpStore.setAnExtendedDelays(timeDifference.value, props.stopSequence);
  else tripUpStore.setADelays(timeDifference.value, props.stopSequence);

  sendTimeUpdateAsTimestampToStore();

  delayTimePicker.value?.closeMenu();
}

function openDatepicker() {
  applyToNextStops.value = false;
  scrollToTimePicker();
  valueOnTimepickerOpen.value = cloneDeep(updatedTime.value);
  // block open if feature not allowed
  if (!tripUpStore.allowDeviationFeature) {
    emit('showBlockedModal');
    delayTimePicker.value?.closeMenu();
  }
}

function altPosition() {
  return {
    top: '30px',
    left: '-305px',
  };
}

function scrollToTimePicker() {
  setTimeout(() => {
    const element = document.getElementById(props.stopSequence.toString());
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, 200);
}

function onTextInput(event: Event) {
  if (event?.target) {
    const target = event.target as HTMLTextAreaElement;
    // text Input is returned as format hh:mm, we must convert it to set it in the model
    if (dayjs(target.value, 'HH:mm', true).isValid() || dayjs(target.value, 'HH:mm:ss', true).isValid()) {
      const timeObjectFromInput = timeHHmmToTimeObj(target.value, true);
      if (timeObjectFromInput) updatedTime.value = timeObjectFromInput;
    }
  }
}
</script>

<template>
  <VueDatepicker
    :id="stopSequence"
    ref="delayTimePicker"
    v-model="updatedTime"
    class="delay-time-picker"
    :class="{
      'delay-time-picker__orange': hasTimeChange || tripUpStore.delay,
      'delay-time-picker__red': isTimeInError,
    }"
    time-picker
    disable-time-range-validation
    :clearable="false"
    auto-apply
    :transitions="false"
    position="right"
    :alt-position="altPosition"
    :text-input="textInputOptions"
    :config="{ shadowDom: true, keepActionRow: true, onClickOutside: (validate: () => boolean) => cancel() }"
    :select-text="$t('save')"
    :cancel-text="$t('cancel')"
    @open="openDatepicker()"
    @textInput="onTextInput"
  >
    <template #input-icon>
      <v-icon size="x-small">fa:fas fa-clock</v-icon>
    </template>

    <template #left-sidebar>
      <Btn type="secondary" :disabled="!hasTimeChange" flat @click="resetTimer()">
        <font-awesome-icon icon="fa-redo" class="mr-2" />
        {{ $t('reset') }}
      </Btn>
      <div
        class="delay-time-picker__time-diff"
        :class="{ 'delay-time-picker__time-diff__orange': timeDifference }"
      >
        {{ timeDifferenceText }}
      </div>
    </template>

    <template #action-preview>
      <v-divider class="my-0" />

      <div class="delay-time-picker__action__content">
        <v-checkbox id="next-stops" v-model="applyToNextStops" color="success" hide-details>
          <template #label>
            <span>
              {{ $t('applyToFollowingStops') }}
            </span>
          </template>
        </v-checkbox>
      </div>
    </template>
    <template #action-buttons>
      <Btn type="secondary" @click="cancel">
        {{ $t('cancel') }}
      </Btn>
      <Btn type="primary" @click="saveModification">
        {{ $t('save') }}
      </Btn>
    </template>
  </VueDatepicker>
</template>

<style lang="scss">
.delay-time-picker {
  &__time-diff {
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: $background-variant;
    color: $text-dark-variant;
    font-size: 16px;
    line-height: 36px;
    text-align: center;

    &__orange {
      background-color: $warn-flat;
      color: $warn;
    }
  }

  &__action__content {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0;
    color: $text-dark;

    .v-input {
      font-size: 13px;
    }
  }

  // overright of vue-datepicker theme
  .dp__input {
    width: 60px;
    height: 24px;
    padding: 0 6px 0 0;
    border-color: $border;
    border-radius: 5px;
    color: $text-dark-variant;
    box-shadow: none !important;
    text-align: right;

    &_icon {
      top: 11px;
      left: 6px;
      color: $text-dark-variant;
      font-size: 12px;
    }
  }

  .dp__input_wrap:hover .dp__input {
    border-color: $border;
    background-color: $background-variant;
  }

  .dp__input_focus {
    border-color: $text-dark;
  }

  &__orange {
    .dp__input {
      border-color: rgba($warn, 0.5) !important;
      color: $warn;

      &_icon {
        color: $warn;
      }
    }

    .dp__input_focus {
      border-color: $warn !important;
    }

    .dp__input_wrap:hover .dp__input {
      background-color: $warn-flat !important;
    }
  }

  &__red {
    .dp__input {
      border-color: rgba($danger, 0.5) !important;
      color: $danger;

      &_icon {
        color: $danger;
      }
    }

    .dp__input_focus {
      border-color: $danger !important;
    }

    .dp__input_wrap:hover .dp__input {
      background-color: $light-red !important;
    }
  }

  .dp__selection_preview {
    width: 100%;
    white-space: normal;
  }

  .dp__action_row {
    display: flex;
    flex-direction: column;
    padding: initial;
  }

  .dp--tp-wrap {
    margin: auto;
  }

  .dp__inc_dec_button:hover {
    background: $border;
  }

  .dp__inc_dec_button_disabled,
  .dp__inc_dec_button_disabled:hover {
    background: none;
    color: $transparent-border-variant;
  }

  .dp__overlay {
    height: 120px !important;
    background-color: transparent;
  }

  .dp__action_buttons {
    flex-basis: auto;
    gap: 8px;
    justify-content: center;
    width: 100%;
    padding: 16px;
    font-size: 14px;

    .ui-btn {
      width: 100%;
    }
  }

  .dp__outer_menu_wrap {
    right: 10px !important;
  }

  .dp__menu {
    width: 375px;
  }

  .dp__instance_calendar {
    width: 50%;
  }

  .dp__time_col_reg_block {
    padding: 0 16px;
  }

  .dp__sidebar_left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
    padding: 20px;

    button {
      width: 100%;
      cursor: pointer;
    }

    .ui-btn {
      height: 36px;
      font-weight: 500;
    }
  }
}
</style>

<i18n locale="fr">
  {
    "applyToFollowingStops": "Appliquer le décalage horaire aux arrêts suivants",
  }
</i18n>

<i18n locale="en">
  {
    "applyToFollowingStops": "Apply the time difference to the following stops",
  }
</i18n>
