import { defineStore } from 'pinia';
import Api from '@/api';
import { arrToFakeMap } from '@/libs/helpers/objects';
import { computed, ref } from 'vue';
import type { Dictionary } from '@/@types/global';
import { useStore } from 'vuex';

export interface DepotCoordinates {
  latitude: number;
  longitude: number;
}

export interface Depot {
  id: string;
  name: string;
  location: Partial<DepotCoordinates>;
  radius: number;
}

export interface MapDepot {
  id: string;
  latitude?: number;
  longitude?: number;
  radius: number;
  name: string;
}

export interface DepotCreate {
  name: string;
  location: DepotCoordinates;
  radius: number;
}

export const useDepotsStore = defineStore('depots', () => {
  const store = useStore();
  const groupId = computed<string>(() => store.getters.group._id);
  const list = ref<Dictionary<Depot>>({});

  function setList(newList: Dictionary<Depot>) {
    list.value = newList;
  }

  async function loadList() {
    const depots = await Api.depot.getDepots(groupId.value);
    if (Array.isArray(depots)) {
      setList(arrToFakeMap(depots, 'id'));
    }
  }

  async function createDepot(groupId: string, depot: DepotCreate) {
    await Api.depot.addDepot(groupId, depot);
  }

  async function updateDepot(groupId: string, depotId: string, depot: Depot) {
    await Api.depot.updateDepot(groupId, depotId, depot);
  }

  async function deleteDepot(groupId: string, depotId: string) {
    await Api.depot.deleteDepot(groupId, depotId);
  }

  return {
    list,
    loadList,
    createDepot,
    updateDepot,
    deleteDepot,
  };
});
