<template>
  <div class="no-device">
    <div class="no-device__message">{{ $t('noDevice') }}</div>
    <Btn type="primary" :route="{ name: GroupRoute.TRIP_LIST }">
      <font-awesome-icon icon="fa-bus" />
      <span>{{ $t('seeTrips') }}</span>
    </Btn>
  </div>
</template>

<script>
import { GroupRoute } from '@/libs/routing';
import Btn from '@/components/ui/Btn.vue';

export default {
  name: 'NoDeviceConnected',

  components: {
    Btn,
  },

  data() {
    return {
      GroupRoute,
    };
  },
};
</script>

<style lang="scss" scoped>
.no-device {
  height: 8em;
  padding: 15px;
  border: 2px dashed $text-neutral;
  border-radius: 8px;
  background-image: url('@/assets/img/no-device-connected.svg');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;

  svg {
    height: 16px;
  }

  &__message {
    margin-bottom: 0.5em;
    color: $text-dark-variant;
    font-weight: $font-weight-semi-bold;
    font-size: 1.2em;
  }
}
</style>

<i18n locale="fr">
  {
    "noDevice": "Aucun appareil connecté",
    "seeTrips": "Voir les courses"
  }
  </i18n>

<i18n locale="en">
  {
    "noDevice": "No connected devices",
    "seeTrips": "See trips"
  }
</i18n>
