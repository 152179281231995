<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import Api from '@/api';
import FeatureBlockedView from '@/components/common/FeatureBlockedView.vue';
import { Feature } from '@/features';

import ArchivedUrgenciesTable from './ArchivedUrgenciesTable.vue';
import CurrentUrgencies from './CurrentUrgencies.vue';

const store = useStore();
const { t } = useI18n();

/** @type {import('vue').Ref<boolean>} */
const acknowledgedTransition = ref(false);
/** @type {import('vue').Ref<Array<import('@/@types/api/urgency').Urgency>>} */
const archivedUrgencies = ref([]);

/** @return {Array<import('@/@types/api/urgency').Urgency>} */
const acknowledgedUrgencies = ref(store.getters['urgencies/getAcknowledgedUrgencies']);

/** @type {import('vue').ComputedRef<boolean>} */
const hasUrgenciesFeatures = computed(() => store.getters.hasFeature(Feature.URGENCIES));

/** @type {import('vue').ComputedRef<String[]>} */
const blockedModalList = computed(() => {
  // generate translated text for each bullet point of blocked modal
  return [...Array(2)].map((_, i) => [t(`fullString${i + 1}`), t(`highlightedString${i + 1}`)]);
});

store.watch(
  () => store.state.urgencies.list,
  () => {
    acknowledgedUrgencies.value = store.getters['urgencies/getAcknowledgedUrgencies'];
  },
  { deep: true },
);

async function getArchivedUrgencies() {
  archivedUrgencies.value = await Api.urgencies.getArchivedUrgencies(store.getters.group._id);
}

async function loadDrivers() {
  await store.dispatch('drivers/loadList');
}

watch(acknowledgedUrgencies, (newUrgencies, initialUrgencies) => {
  if (initialUrgencies.length === 0 && newUrgencies.length > 0) {
    acknowledgedTransition.value = true;

    // Reset transition
    setTimeout(() => {
      acknowledgedTransition.value = false;
    }, 2000);
  }
  // Refresh archived urgencies data when an urgency is resolved
  if (newUrgencies.length < initialUrgencies.length) {
    setTimeout(() => {
      getArchivedUrgencies();
    }, 1000);
  }
});

onMounted(() => {
  if (hasUrgenciesFeatures.value) {
    loadDrivers();
    getArchivedUrgencies();
  }
});
</script>

<template>
  <div class="urgencies-view" :class="{ 'urgencies-view__padding': hasUrgenciesFeatures }">
    <template v-if="hasUrgenciesFeatures">
      <CurrentUrgencies
        v-if="acknowledgedUrgencies.length > 0"
        :urgencies="acknowledgedUrgencies"
        :class="{
          'acknowledged--transition': acknowledgedTransition,
        }"
      />
      <ArchivedUrgenciesTable
        :show-full-height="acknowledgedUrgencies.length === 0"
        :archived-urgencies="archivedUrgencies"
      />
    </template>
    <FeatureBlockedView
      v-else
      :title="t('urgencies')"
      :text="blockedModalList"
      modal-img="urgencies-blocked-modal"
      background-img="urgencies-blocked-view"
    />
  </div>
</template>

<style lang="scss">
.urgencies-view {
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__padding {
    padding: $view-standard-padding;
  }

  .acknowledged--transition {
    animation: slide-down 1.5s ease;
  }
}
</style>

<i18n locale="fr">
  {
    "fullString1": "Bouton \"SOS\" dans l'application Pysae Driver",
    "highlightedString1": "Bouton \"SOS\"",
    "fullString2": "Réception et gestion des alertes conducteurs",
    "highlightedString2": "alertes conducteurs",
    "urgencies": "Alertes urgentes"
  }
  </i18n>

<i18n locale="en">
  {
    "fullString1": "\"SOS\" button in the Pysae Driver application",
    "highlightedString1": "\"SOS\" button",
    "fullString2": "Reception and handling of driver alerts",
    "highlightedString2": "driver alerts",
    "urgencies": "Urgent alerts"
  }
  </i18n>
