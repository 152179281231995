<script setup lang="ts">
import Slider from '@vueform/slider';
import VueDatepicker from '@vuepic/vue-datepicker';
import dayjs from 'dayjs';
import Btn from '@/components/ui/Btn.vue';
import {
  dateObjToGtfsFormat,
  timestampFormatHHMM,
  timeHHmmToTimeObj,
  timeObjToTimestamp,
  type TimeObject,
  getTzDelta,
} from '@/libs/helpers/dates';
import { computed, onMounted, ref, watch, type PropType } from 'vue';
import { useStore } from 'vuex';
import type { DeviceEvent } from '@/@types/device';

const store = useStore();

const props = defineProps({
  events: {
    required: true,
    type: Array as PropType<Array<DeviceEvent>>,
  },
  tz: {
    type: String,
    required: true,
  },
  selectedDate: {
    type: Date,
    default: () => new Date(),
  },
  isTripRunning: {
    type: Boolean,
    default: false,
  },
});

const textInputOptions = {
  format: 'HH:mm',
};

const sliderValue = ref<number>(0);
const isLeftArrowUpdate = ref<boolean>(false);
const outsideUpdate = ref<boolean>(false);
const eventTimePicker = ref<TimeObject>();
const eventIdx = ref<number>(0);
const realTimeOn = ref<boolean>(false);

const isUserSliding = ref<boolean>(false);
const firstTs = ref<number>(0);
const lastTs = ref<number>(0);

const dateOfDayGtfs = computed<string>(() => dateObjToGtfsFormat(new Date()));
const selectedDateGtfs = computed<string>(() => dateObjToGtfsFormat(props.selectedDate));
const dayPast = computed<boolean>(() => selectedDateGtfs.value < dateOfDayGtfs.value || !props.events.length);
const timestamp = computed<number>(() => store.state.tripDetailed.focusedTimeStamp);

watch(
  () => dayPast.value,
  () => {
    eventIdx.value = props.events.length - 1;
    if (!dayPast.value) realTimeOn.value = true;
    else realTimeOn.value = false;
  },
);

watch(
  () => timestamp.value,
  () => {
    const currentTs = props.events[eventIdx.value]?.ts;
    // Remove realTime if timestamp is emited from another component & time is not last event
    if (realTimeOn.value && timestamp.value !== props.events[props.events.length - 1]?.ts) {
      realTimeOn.value = false;
    }

    // Update slider value only if timestamp is updated from another component
    if (!realTimeOn.value && timestamp.value !== currentTs) {
      outsideUpdate.value = true;
      sliderValue.value = timestamp.value;
    }
  },
);

watch(
  () => sliderValue.value,
  () => {
    const eventCounts = props.events.length;
    if (realTimeOn.value && sliderValue.value !== props.events[eventCounts - 1]?.ts) {
      realTimeOn.value = false;
    }
    if (realTimeOn.value) {
      eventIdx.value = eventCounts - 1;
    } else {
      // Search for the nearest event object based on ts
      const upperResult = props.events.filter(event => event.ts > sliderValue.value);
      let result = null;
      if (upperResult && upperResult.length > 0)
        result = upperResult.reduce((a, b) => {
          return Math.abs(b.ts - sliderValue.value) <= Math.abs(a.ts - sliderValue.value) ? b : a;
        });

      // set default index to 0, then search for real index depending on context
      let index = 0;
      if (result) {
        index = props.events.findIndex(event => event.ts === result.ts);
      } else if (isLeftArrowUpdate.value) {
        // No result, Arrow picker case : If last index, try to select a value before last index
        index = eventCounts - 2;
        isLeftArrowUpdate.value = false;
      } else {
        // case we are at the end of slider
        return;
      }
      eventIdx.value = index;
    }

    eventTimePicker.value = timeHHmmToTimeObj(
      timestampFormatHHMM(props.events[eventIdx.value]?.ts, { tz: props.tz }),
    );
  },
);

watch(
  () => props.events,
  val => {
    if ((realTimeOn.value || dayPast.value) && val.length) {
      initTimeline();
    }

    // set first Ts for timeline
    if (!firstTs.value && props.events[0]) {
      firstTs.value = props.events[0].ts;
    }
    // set last ts for timeline
    // No update on lastTs while user sliding to avoid unwanted behavior (slider is unfocused)
    if (!isUserSliding.value && props.events.length) {
      lastTs.value = props.events[props.events.length - 1].ts;
    }

    if (realTimeOn.value) {
      sliderValue.value = props.events[props.events.length - 1].ts;
    }
  },
  {
    deep: true,
    immediate: true,
  },
);
watch(
  () => eventIdx.value,
  () => {
    // Don't dispatch if update come from store
    if (props.events[eventIdx.value] && !outsideUpdate.value) {
      store.dispatch('tripDetailed/setFocusedByTimeline', {
        timestamp: props.events[eventIdx.value].ts,
        stopId: props.events[eventIdx.value].stop_id,
        status: props.events[eventIdx.value].current_status,
      });
    }
    outsideUpdate.value = false;
  },
);

onMounted(() => {
  // Implement key left & right to change time
  window.addEventListener('keydown', e => {
    const focusedElementsToIgnore = document?.activeElement?.classList;
    // check if we're not focused on input or slider-handler to avoid bugs
    if (
      focusedElementsToIgnore &&
      !focusedElementsToIgnore.contains('slider-handle') &&
      !focusedElementsToIgnore.contains('dp__input') &&
      !focusedElementsToIgnore.contains('mapboxgl-canvas')
    ) {
      if (e.key === 'ArrowRight') {
        sliderValue.value += 20;
      } else if (e.key === 'ArrowLeft') {
        isLeftArrowUpdate.value = true;
        sliderValue.value -= 20;
      }
    }
  });
  initTimeline();
});

function initTimeline() {
  if (props.isTripRunning) {
    realTimeOn.value = true;
    eventIdx.value = props.events.length - 1;
  } else {
    realTimeOn.value = false;
    eventIdx.value = 0;
  }
  sliderValue.value = props.events[eventIdx.value].ts;
}

/**
 * action in switch real time
 */
function switchRealTime() {
  if (realTimeOn.value) realTimeOn.value = false;
  else {
    realTimeOn.value = true;
    sliderValue.value = props.events[props.events.length - 1].ts;
  }
}

/**
 * Take a timestamp and return the time value in HH:mm format
 */
function displayedValue(timestamp: number): string {
  return timestampFormatHHMM(timestamp, { tz: props.tz });
}

/**
 * Take a timeObject and convert it to a timestamp (depending on selectedDate) given to the slider
 */
function handleUpdateTimepicker(value: TimeObject) {
  const newTs = timeObjToTimestamp(selectedDateGtfs.value, value) - getTzDelta(props.selectedDate, props.tz);
  sliderValue.value = newTs;
}
/**
 * Action on focus Timepicker : disable realTime actualization
 */
function handleFocusTimepicker() {
  realTimeOn.value = false;
}

/**
 * take a timestamp and return an object time with only hours
 * datepicker min/max hours does not work correctly so we only limit hours
 */
function calculateMinMaxTime(timestamp: number, last: boolean = false): Partial<TimeObject> {
  const time = dayjs.unix(timestamp + getTzDelta(props.selectedDate, props.tz));
  let hour = time.hour();
  if (last) hour += 1;
  return {
    hours: hour,
    minutes: undefined,
    seconds: undefined,
  };
}
function slideStart() {
  realTimeOn.value = false;
  isUserSliding.value = true;
}
function slideEnd() {
  isUserSliding.value = false;
}
</script>

<template>
  <div ref="timeline" class="timeline">
    <VueDatepicker
      :class="{ active: !realTimeOn }"
      :model-value="eventTimePicker"
      time-picker
      disable-time-range-validation
      :clearable="false"
      position="left"
      :min-time="calculateMinMaxTime(firstTs)"
      :max-time="calculateMinMaxTime(lastTs, true)"
      :text-input="textInputOptions"
      hide-input-icon
      :select-text="$t('timePickerConfirm')"
      :cancel-text="$t('timePickerCancel')"
      @update:modelValue="handleUpdateTimepicker"
      @focus="handleFocusTimepicker"
    ></VueDatepicker>

    <div>
      <Btn
        v-if="isTripRunning"
        class="timeline__btn"
        :class="{ active: realTimeOn }"
        type="link-style"
        @click="switchRealTime()"
      >
        {{ $t('realTime') }}
      </Btn>
    </div>

    <Slider
      v-model="sliderValue"
      show-tooltip="focus"
      :min="firstTs"
      :lazy="realTimeOn ? true : false"
      :format="displayedValue"
      :max="lastTs"
      class="timeline__slider"
      @start="slideStart()"
      @end="slideEnd()"
    />
  </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss">
.timeline {
  --slider-bg: #e6e6e6; // $border
  --slider-connect-bg: #b3b3b3; // $border-variant
  --slider-handle-ring-color: #00000025;
  --slider-height: 4px;
  --slider-tooltip-bg: #00b871; // $primary-light
  --dp-font-family: 'Poppins', arial, sans-serif;

  display: flex;
  height: 36px;
  border: solid 1px $border;
  border-radius: 7px;
  background-color: $canvas;

  &__btn {
    width: 100px;
    height: 25px;
    margin-top: 5px;
    padding: 0 10px;
    border-radius: 3px;
    background-color: $background !important;
    text-align: center !important;

    &.active {
      background-color: $green-flat !important;
      color: $primary-light;
    }

    &:hover {
      text-decoration: none !important;
    }
  }

  &__slider {
    width: 100%;
    margin: 15px 20px 10px 10px;
  }

  // Datepicker
  .dp__main {
    width: 85px;
    height: 10px;
    margin-right: 10px;
    padding: 2px;
  }

  .dp__overlay {
    border-radius: 9px;
  }

  // dp input
  .dp__input {
    height: 25px;
    margin: 3px 0 0 3px;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: $background;
    font-size: 14px;
    text-align: center;
  }

  .active {
    .dp__input {
      background-color: $green-flat;
      color: $primary-light;
    }
  }

  .dp__input_focus {
    box-shadow: none;
  }

  .dp__action_button {
    margin-left: 8px;
    padding: 3px 5px;
    border-radius: 5px;
    font-family: $font-poppins;
  }

  .dp__action_select:disabled {
    background: $text-neutral;
    cursor: not-allowed;
  }

  .dp__action_cancel:hover {
    border-color: $border-variant;
    background-color: $border;
  }

  .dp__action_select:hover:not(:disabled) {
    background-color: darken($primary-light, 5%);
  }

  .dp__inc_dec_button:hover {
    background: $border;
  }

  .dp__inc_dec_button_disabled,
  .dp__inc_dec_button_disabled:hover {
    background: none;
    color: $transparent-border-variant;
  }

  .dp__selection_preview {
    color: transparent;
  }

  .dp__time_display {
    min-width: 45px;
    padding: 5px;
    border-radius: 16px;
  }

  .dp__time_col_reg {
    padding: 0 15px;
  }

  .dp__menu {
    left: 260px !important;
  }

  .dp__arrow_bottom {
    left: 30px;
  }
}
</style>

<i18n locale="fr">
{
  "realTime": "Temps réel",
  "timePickerCancel": "Annuler",
  "timePickerConfirm": "Valider"
}
</i18n>

<i18n locale="en">
{
  "realTime": "Real time",
  "timePickerCancel": "Cancel",
  "timePickerConfirm": "Confirm"
}
</i18n>
