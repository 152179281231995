<template>
  <div class="sort-filter-buttons">
    <div
      class="sort-filter-buttons__dropdown-selector dropdown"
      :class="{ 'dropdown--open': dropdownOpened }"
    >
      <Btn
        class="sort-filter-buttons__button"
        type="secondary-active"
        @click="dropdownToggle"
        @mousedown.prevent
      >
        <span>{{ sorting.field.label }}</span>
      </Btn>
      <ul class="dropdown__menu" @click.stop>
        <li
          v-for="option in fields"
          :key="`${option.id.toString()}_dropdown`"
          class="dropdown__item"
          @click="changeField(option)"
        >
          <label :for="option.id.toString()">{{ option.label }}</label>
        </li>
      </ul>
    </div>
    <Btn
      class="sort-filter-buttons__button-sort"
      type="secondary-active"
      @click="changeDirection"
      @mousedown.prevent
    >
      <font-awesome-icon
        :icon="
          sorting.direction === SortDirection.ASC ? 'fa-arrow-down-wide-short' : 'fa-arrow-up-wide-short'
        "
      />
    </Btn>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';

export const SortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export default {
  name: 'SortFilterButtons',

  components: {
    Btn,
  },

  props: {
    /** @type {import('vue').Prop<Array<FieldOptions>>} */
    fields: {
      type: Array,
      required: true,
    },
    /** @type {import('vue').Prop<SortFilter>} */
    sorting: {
      type: Object,
      required: true,
    },
    lsStorageKey: {
      type: String,
      required: true,
    },
  },
  emits: ['update:sorting'],

  data() {
    return {
      SortDirection,
      dropdownOpened: false,
    };
  },
  computed: {
    /** @return {string} */
    groupId() {
      return this.$store.getters.group._id;
    },
  },

  created() {
    // Check if user has saved sorting in LS
    this.searchLsAndLoadSorting();
  },

  methods: {
    /**
     * Close the opened dropdown
     */
    dropdownClose() {
      this.dropdownOpened = false;
      window.removeEventListener('click', this.dropdownClose);
    },

    /**
     * Open a dropdown
     */
    dropdownOpen() {
      this.dropdownOpened = true;
      window.removeEventListener('click', this.dropdownClose);
      setTimeout(() => window.addEventListener('click', this.dropdownClose), 10);
    },

    /**
     * Call dropdownOpen or dropdownClose
     */
    dropdownToggle() {
      if (!this.dropdownOpened) {
        this.dropdownOpen();
      } else {
        this.dropdownClose();
      }
    },
    changeDirection() {
      const newSorting = this.sorting;
      if (this.sorting.direction === SortDirection.ASC) newSorting.direction = SortDirection.DESC;
      else newSorting.direction = SortDirection.ASC;
      this.changeSorting(newSorting);
    },

    changeField(field) {
      const newSorting = this.sorting;
      newSorting.field = field;
      this.changeSorting(newSorting);
      this.dropdownClose();
    },

    /**
     * Emit sorting update to parent & save it to LS
     * @param {SortFilter} sorting
     */
    changeSorting(sorting) {
      this.saveSortingToLS(sorting);
      this.$emit('update:sorting', sorting);
    },

    searchLsAndLoadSorting() {
      const savedSorting = this.getLsSorting();
      if (savedSorting) {
        this.$emit('update:sorting', savedSorting);
      }
    },

    /**
     * @param {SortFilter} sorting
     */
    saveSortingToLS(sorting) {
      localStorage.setItem(this.lsStorageKey, JSON.stringify({ groupId: this.groupId, sorting }));
    },

    /**
     * @return {SortFilter}
     */
    getLsSorting() {
      const stored = JSON.parse(localStorage.getItem(this.lsStorageKey));
      // Keep filter only if groupId match
      if (stored && stored.groupId === this.groupId) return stored.sorting;
      return null;
    },
  },
};

/**
 * @typedef {Object} SortFilter
 * @property {FieldOptions} field
 * @property {string} direction
 */

/**
 * @typedef {Object} FieldOptions
 * @property {string} id
 * @property {string} label
 */
</script>

<style lang="scss">
.sort-filter-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 36px;

  .ui-btn {
    border-radius: 46px;
  }

  .dropdown__menu {
    width: 175px;
    margin-top: 10px;
    border-color: $border-variant;
    border-radius: 4px;
    background-color: $canvas;
  }

  &__button {
    width: 115px;
    height: 36px;

    @media (max-width: 500px) {
      width: auto;
      font-size: 12px;
    }

    span {
      display: inline-block;
      overflow: hidden;
      max-width: 90px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__button-sort {
    padding: 8px 16px;

    svg {
      height: 16px;
    }
  }

  &__dropdown-selector {
    .dropdown__item {
      padding: 8px;

      &:hover {
        background-color: $border;
        cursor: pointer;
      }

      label {
        cursor: pointer;
      }
    }
  }
}
</style>
